import styled from 'styled-components';
import { font } from '@/style/mixins';

export const Container = styled.a`
    position: relative;
    ${font('text3-r')};

    svg {
        width: 11px;
        height: 10px;

        line {
            stroke: currentColor;
        }
        path {
            fill: currentColor;
        }
    }
`;
