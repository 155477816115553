import styled from 'styled-components';
import { color, font, hover, offset } from '@/style/mixins';

export const Container = styled.label<{ hide?: boolean }>`
    position: relative;
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    align-items: center;
    width: auto;
    cursor: pointer;

    ${hover(`
        span {
            border-color: ${color('Gray/1000')};
        }
        
        p {
            color: ${color('link-hover')};
        }
    `)};

    p {
        transition: color 0.4s ease-in-out;
        ${font('text2-sb')};

        span {
            ${font('text2-b')};
        }
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        cursor: pointer;

        &:checked {
            & ~ span {
                background-color: ${color('DeepBlue/500')};
                border-color: ${color('DeepBlue/500')};

                svg {
                    visibility: visible;
                    opacity: 1;
                }
            }

            & ~ p {
                color: ${color('ui-text-default')};
            }
        }
    }

    & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 16px;
        height: 16px;
        margin-right: 8px;
        background-color: transparent;
        border: 1px solid ${color('ui-icon-default')};
        border-radius: 3px;
        transition: background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;

        svg {
            width: 8px;
            height: 6px;
            fill: ${color('white')};
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
        }
    }

    &.itemForm {
        align-items: flex-start;
        &:not(:first-of-type) {
            ${offset('xxs', 'margin-top')};
        }
        p {
            margin-top: 0;
        }
    }

    &._error {
        span {
            border-color: ${color('Error')};
        }
    }
`;
