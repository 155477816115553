import React, { useState, useEffect, ChangeEvent } from 'react';
import { Container, ErrorText, PhoneInputs, PhonePlaceholder, PhoneSelect, PhoneWrapper, Descr, PlaceholderTop } from './InputForm.styled';
import { Control, Controller } from 'react-hook-form/dist/index.ie11';
import PatternFormat from 'react-number-format';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
import { PHONE_FORMAT, PHONE_FORMAT_PLACEHOLDER, PHONE_OPTIONS } from '@/helpers/configRegExp';
import useDebounce from '@/hooks/useDebounce';
import { OptionDataI } from '@/interfaces';

export const enum FormInputNames {
    inn = 'inn',
    usualText = 'usualText',
    email = 'email',
    site = 'site',
    phone = 'phone',
    message = 'message',

    another = 'another'
}

export type PhoneFormat = keyof typeof PHONE_FORMAT;

interface InputFormProps {
    register?: any;
    name: string;
    inputName: FormInputNames;
    type?: string;
    placeholder?: string;
    control?: Control | null;
    required?: boolean;
    textarea?: boolean;
    maxLength?: number;
    errors?: {
        type?: string;
        message?: string;
    };
    rules?: any;
    formatLang?: PhoneFormat;
    setValue?: UseFormMethods['setValue'];
    description?: string;
    className?: string;
    phoneOptions?: OptionDataI[];
}

const anotherOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.trimStart().replace(/  $/, '');
};

const siteOnChange = (e: ChangeEvent<HTMLInputElement>) => (e.target.value = e.target?.value.substring(0, 100));

const usualTextOnChange = (e: ChangeEvent<HTMLInputElement>, maxLength: number = 100) => {
    let text = e.target.value.trimStart();

    text = text.replace(/[^\wа-яА-Я.\-—“”", ]+/g, '');
    text = text.substring(0, maxLength);
    text = text.replace(/  $/, '');

    e.target.value = text;
};

const textareaOnChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, maxLength: number = 500) =>
    (e.target.value = e.target?.value
        .trimStart()
        .replace(/[^\wа-яА-Я.\-—“”"?, ]+/g, '')
        .substring(0, maxLength));

const innInputOnChange = (e: ChangeEvent<HTMLInputElement>, maxLength: number = 20) => {
    let text = e.target.value.trimStart();

    text = text.substring(0, maxLength);
    text = text.replace(/  /g, '');

    e.target.value = text;
};

const mailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match('@')) {
        const zxc = e.target.value.split('@');
        zxc[0].substring(0, 64);
        zxc[1].substring(zxc[0].length, 320);
        e.target.value = zxc[0].substring(0, 64) + '@' + zxc[1].substring(0, 255);
    }

    e.target.value = e.target?.value.replace(/[^\w-.@]/g, '').substring(0, 320);
};

const checkEmptyPhone = (value: string, formatLang: InputFormProps['formatLang']) => {
    let result = '';

    if (!formatLang || typeof formatLang !== 'string') return result;

    if (formatLang === 'ru' || formatLang === 'bel') {
        result = value.replace(/[^\d]/g, '').substring(formatLang === 'ru' ? 1 : 3);
    } else {
        result = value.replace(/[^\d]/g, '').substring(formatLang.length - 1);
    }

    return result;
};

const getOnChangeHandler = (inputName: string, e: ChangeEvent<HTMLInputElement>, maxLength?: number) => {
    switch (inputName) {
        case FormInputNames.inn:
            return innInputOnChange(e, maxLength);
        case FormInputNames.usualText:
            return usualTextOnChange(e, maxLength);
        case FormInputNames.email:
            return mailOnChange(e);
        case FormInputNames.site:
            return siteOnChange(e);
        case FormInputNames.another:
            return anotherOnChange(e);
        default:
            return;
    }
};

const InputForm: React.FC<InputFormProps> = ({
    placeholder,
    type,
    name,
    inputName,
    required,
    register,
    errors,
    control,
    rules,
    textarea,
    maxLength,
    formatLang,
    setValue,
    description,
    className,
    phoneOptions,
    ...rest
}) => {
    const [showMask, setShowMask] = useState(false);
    const [showPhonePlaceholder, setShowPhonePlaceholder] = useState(true);

    const [activeFormatLang, setActiveFormatLang] = useState(formatLang || 'ru');
    const [phoneVal, setPhoneVal] = useState<string>('');

    const debouncedShowPhonePlaceholder = useDebounce(showPhonePlaceholder, 10);

    const [withValue, setWithValue] = useState(false);

    useEffect(() => {
        if (setValue && type === 'tel') {
            setValue(name, '');
            setPhoneVal('');
        }
    }, [activeFormatLang]);

    return (
        <Container
            className={`${className ? className : ''} itemForm ${errors ? '_error' : ''} ${textarea ? '_textarea' : ''}`}
            withValue={withValue}
        >
            {control && type === 'tel' && (
                <PhoneWrapper
                    onClick={() => {
                        setShowPhonePlaceholder(false);
                    }}
                    className={`${debouncedShowPhonePlaceholder ? '_phShow' : ''}`}
                >
                    <PhoneInputs>
                        {((phoneOptions?.length && phoneOptions.length > 1) || !phoneOptions) && (
                            <PhoneSelect
                                options={phoneOptions ?? PHONE_OPTIONS}
                                onChange={(optionPhone) => setActiveFormatLang(optionPhone.value)}
                                onBlur={() => {
                                    setShowPhonePlaceholder(!checkEmptyPhone(phoneVal, activeFormatLang).length);
                                }}
                            />
                        )}
                        <Controller
                            render={({ onChange, onBlur, value, ref }) => (
                                <PatternFormat
                                    format={formatLang ? PHONE_FORMAT[activeFormatLang] : PHONE_FORMAT.ru}
                                    allowEmptyFormatting={showMask}
                                    mask="_"
                                    getInputRef={ref}
                                    onBlur={() => {
                                        setShowMask(false);
                                        setPhoneVal(value);
                                        setShowPhonePlaceholder(!checkEmptyPhone(value, activeFormatLang).length);
                                        onBlur();
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    onFocus={() => {
                                        setShowMask(true);
                                        setShowPhonePlaceholder(false);
                                    }}
                                    className="tel-input"
                                    placeholder={`${PHONE_FORMAT_PLACEHOLDER[activeFormatLang] || placeholder}${!!required ? ' *' : ''}`}
                                />
                            )}
                            name={name}
                            defaultValue={''}
                            control={control}
                            type={type}
                            rules={rules ? rules(activeFormatLang) : { required: 'phone required' }}
                            className={'inputForm'}
                        />
                    </PhoneInputs>
                    <PhonePlaceholder>
                        <span>
                            {placeholder}
                            {!!required && ' *'}
                        </span>
                    </PhonePlaceholder>
                </PhoneWrapper>
            )}
            {!control &&
                !textarea &&
                (inputName === FormInputNames.inn ||
                    inputName === FormInputNames.usualText ||
                    inputName === FormInputNames.email ||
                    inputName === FormInputNames.another ||
                    inputName === FormInputNames.site) && (
                    <input
                        placeholder={`${placeholder}${!!required ? ' *' : ''}`}
                        type={inputName === FormInputNames.email ? 'text' : type}
                        name={name}
                        ref={register}
                        maxLength={maxLength}
                        className={'inputForm'}
                        {...rest}
                        onChange={(e) => {
                            getOnChangeHandler(inputName, e, maxLength);
                            setWithValue(!!e.target.value);
                        }}
                    />
                )}

            {!control &&
                !textarea &&
                inputName !== FormInputNames.inn &&
                inputName !== FormInputNames.usualText &&
                inputName !== FormInputNames.email &&
                inputName !== FormInputNames.another &&
                inputName !== FormInputNames.site && (
                    <input
                        placeholder={`${placeholder}${!!required ? ' *' : ''}`}
                        type={type}
                        name={name}
                        ref={register}
                        maxLength={maxLength}
                        className={'inputForm'}
                        onChange={(e) => setWithValue(!!e.target.value)}
                        {...rest}
                    />
                )}

            {!control && textarea && (
                <textarea
                    placeholder={`${placeholder}${!!required ? ' *' : ''}`}
                    name={name}
                    ref={register}
                    onChange={(e) => {
                        textareaOnChange(e);
                        setWithValue(!!e.target.value);
                    }}
                    {...rest}
                    className={'inputForm'}
                />
            )}
            {errors?.message && <ErrorText>{errors?.message}</ErrorText>}
            {placeholder && <PlaceholderTop withValue={withValue}>{placeholder}</PlaceholderTop>}

            {!!description && <Descr>{description}</Descr>}
        </Container>
    );
};

export default InputForm;
