import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Index: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/': {
        ...SAMPLES.SERVICE_BLOCKS_INTERACTION_CARDS,
        ...SAMPLES.INDEX_CONTENT_TABS_FOR_SUPPLIERS,
        ...SAMPLES.INDEX_NEWS_SECTION,
        ...SAMPLES.BANNERS_SECTION
    }
};
