import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Finance: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/fin-services': SAMPLES.FINANCE_SERVICES_SAMPLES,
    '/fin-services/bg': {
        ...SAMPLES.INDEPENDENT_GUARANTEE_SAMPLES,
        ...SAMPLES.INDEPENDENT_GUARANTEE_SUPPORT_SECTION
    }
};
