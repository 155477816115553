import { useRouter } from 'next/router';

export const useURLFormData = () => {
    const {
        asPath,
        query: { utm_source, utm_medium, utm_campaign, utm_content, utm_term }
    } = useRouter();

    return {
        utm_source: utm_source ? (Array.isArray(utm_source) ? utm_source[0] : utm_source) : '',
        utm_medium: utm_medium ? (Array.isArray(utm_medium) ? utm_medium[0] : utm_medium) : '',
        utm_campaign: utm_campaign ? (Array.isArray(utm_campaign) ? utm_campaign[0] : utm_campaign) : '',
        utm_content: utm_content ? (Array.isArray(utm_content) ? utm_content[0] : utm_content) : '',
        utm_term: utm_term ? (Array.isArray(utm_term) ? utm_term[0] : utm_term) : '',
        pagePath: asPath.split('?')[0]
    };
};
