import styled from 'styled-components';
import { color, font, offset, mediaBreakpointUp, vw, hover, mediaBreakpointDown } from '@/style/mixins';
import RectLink from '@/components/ui/RectLink/RectLink';
import Button from '@/components/blocks/Button/Button';

export const Container = styled.div`
    position: relative;
    color: ${color('text-primary')};
    flex-grow: 1;
    ${font('h1')};
    ${offset('xl', 'padding-top')}
    ${offset('xxxl', 'padding-bottom')}

    ${mediaBreakpointDown('xl')} {
        padding-left: 20px;
        padding-right: 20px;
    }

    & > div {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;

        ${mediaBreakpointUp('md')} {
            justify-content: flex-start;
        }

        ${mediaBreakpointUp('lg')} {
            gap: ${vw(20)};
        }

        a {
            border: 1px solid ${color('divider-onwhite')};
            border-radius: 4px;
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;

            ${mediaBreakpointUp('md')} {
                width: fit-content;
            }

            svg {
                transition: opacity 0.4s;

                path {
                    fill: ${color('text-primary')};
                }
            }

            ${hover(`
            svg{
                opacity:0.5;
            }
        `)};
        }
    }
`;

export const ButtonError = styled(Button)`
    color: ${color('white')};
    background-color: ${color('DeepBlue/900')};

    ${hover(`
    color: ${color('white')};
    background-color: ${color('DeepBlue/400')};
`)};
`;

export const ErrorPageTitle = styled.h1`
    ${offset('m', 'margin-bottom')};
`;

export const ErrorPageText = styled.p`
    ${font('t1-body-sb')};
    ${offset('l', 'margin-bottom')}

    a {
        color: ${color('link-accent-default')};
    }
`;

export const ErrorPageButton = styled(RectLink)`
    color: ${color('white')};
    border-color: ${color('DeepBlue/900')};
    background-color: ${color('DeepBlue/900')};
    width: fit-content;

    ${hover(`
        color: ${color('white')};
        border-color: ${color('DeepBlue/400')};
        background-color: ${color('DeepBlue/400')};
    `)};
`;
