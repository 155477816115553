import React from 'react';
import Link from 'next/link';
import { MenuLinkTitleI } from '@/interfaces/index';
import { useRouter } from 'next/router';

const PotentiallyLink = ({ href, text, i }: MenuLinkTitleI) => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];
    const isExternalLink = href && href.startsWith('http');
    return (
        <>
            {href && pathname !== href ? (
                <Link href={href} passHref prefetch={false} key={i}>
                    <a
                        {...(isExternalLink && { target: '_blank', rel: 'noopener noreferrer' })}
                        dangerouslySetInnerHTML={{ __html: text }}
                    ></a>
                </Link>
            ) : (
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
            )}
        </>
    );
};
export default PotentiallyLink;
