import { createSlice } from '@reduxjs/toolkit';

export type ourCustomersState = {
    sectionsCustomers: {
        alias: string[];
        slug: string;
        icon?: string;
        name?: string;
    }[];
};

const initialState: ourCustomersState = {
    sectionsCustomers: []
};

const ourCustomersSlice = createSlice({
    name: 'ourCustomers',
    initialState,
    reducers: {
        setSectionsCustomers(state, action) {
            state.sectionsCustomers = action.payload;
        }
    }
});

export const { setSectionsCustomers } = ourCustomersSlice.actions;

export default ourCustomersSlice.reducer;
