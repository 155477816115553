import styled from 'styled-components';
import { font, mediaBreakpointUp, hover, color } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    ${mediaBreakpointUp('md')} {
        padding: 24px;
        border-radius: 20px;
        background-color: ${color('DeepBlue/800')};
    }
`;

export const FooterLogo = styled.a<{ router?: boolean }>`
    width: 136px;
    height: 32px;
    cursor: ${({ router }) => (router ? 'pointer' : 'default')};

    ${mediaBreakpointUp('md')} {
        width: 170px;
        height: 40px;
    }

    svg {
        width: auto;
        height: 100%;
        fill: currentColor;

        .logo-r_t {
            stroke: ${color('brandPrimary')};
            fill: ${color('brandPrimary')};
        }
    }
`;

export const FooterContactsPhones = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 40px;
    ${font('h3')};

    ${mediaBreakpointUp('md')} {
        margin-top: 50px;
    }
    ${mediaBreakpointUp('xl')} {
        margin-top: 60px;
    }

    a {
        transition: color 0.3s ease-in-out;
        ${hover(` color: ${color('white', 0.6)}`)};
    }
`;

export const FooterContactsWrapper = styled.div`
    ${mediaBreakpointUp('md')} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    ${mediaBreakpointUp('xl')} {
        flex-direction: column;
        justify-content: normal;
    }
`;

export const FooterAddress = styled.address`
    ${font('text2-sb')};
    font-style: normal;
    margin-top: 20px;

    ${mediaBreakpointUp('md')} {
        margin-top: 50px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 32px;
    }
`;

export const FooterContactsSocials = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
    gap: 12px;
    ${font('text3-l')};

    ${mediaBreakpointUp('md')} {
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 54px;
        grid-template-rows: repeat(2, 67px);
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${color('DeepBlue/700')};
        border-radius: 10px;
        padding: 14px 0;

        transition: opacity 0.3s ease-in-out;
        ${hover(` opacity: 0.6`)};

        svg {
            fill: currentColor;
            pointer-events: none;
            height: 24px;
            width: 24px;
        }
    }
`;

export const FooterContactsSocialContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: ${color('DeepBlue/700')};
    border-radius: 10px;
    grid-column: span 2;

    ${mediaBreakpointUp('md')} {
        justify-content: flex-start;
        padding: 24px;
        gap: 32px;
    }

    ${mediaBreakpointUp('xl')} {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        grid-column: span 3;
        grid-row: span 2;
    }

    svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }

    ul {
        list-style-type: none;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
        padding: 0;
        margin: 0;

        li {
            width: 100%;
        }
    }

    a {
        padding: 0;
        display: flex;
        justify-content: space-between;
        gap: 15px;
        ${font('text3-sb')};

        ${mediaBreakpointUp('md')} {
            justify-content: flex-start;
        }

        svg {
            width: 10px;
            height: 10px;
            fill: transparent;
            stroke: ${color('white', 0.6)};

            ${mediaBreakpointUp('xl')} {
                display: none;
            }
        }
    }
`;

export const FooterContactsDocs = styled.div`
    cursor: pointer;
    position: relative;
    background-color: ${color('DeepBlue/700')};
    border-radius: 10px;
    padding: 20px 0 20px 24px;
    margin-top: 12px;
    transition: opacity 0.3s ease-in-out;
    ${font('text3-sb')};
    ${hover(`opacity: 0.6}`)};

    div {
        grid-column: span 3;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${color('brandPrimary')};
        border-radius: 6px;
        width: 44px;
        height: 44px;

        svg {
            width: 16px;
            height: 16px;
            stroke: ${color('white')};
        }
    }
    ${mediaBreakpointUp('md')} {
        max-width: 332px;
    }
    ${mediaBreakpointUp('xl')} {
        max-width: 252px;
        margin-top: 32px;
    }
`;

export const FooterAngle = styled.div`
    display: none;

    ${mediaBreakpointUp('md')} {
        display: block;
        width: 48px;
        height: 48px;
        position: absolute;
        bottom: -1px;
        right: 0;

        svg {
            width: inherit;
            height: inherit;
            transform: rotate(-90deg);

            path {
                fill: ${color('Bg-Dark')};
            }
        }
    }
    ${mediaBreakpointUp('xl')} {
        width: 57px;
        height: 57px;
    }
`;
