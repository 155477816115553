import styled from 'styled-components';
import { buttonStyle } from '@/style/mixins';
import { RectLinkProps, RectLinkViewType } from '@/components/ui/RectLink/RectLink';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';
import { Colors } from '@/style/colors';

type BtnColors = Record<RectLinkViewType, Colors>;

const btnBgColors: BtnColors = {
    stroke: 'white',
    'stroke-white': 'white',
    'fill-white': 'white',
    'fill-black': 'Content-04',
    'fill-blue': 'blue',
    'fill-bg-dark': 'DeepBlue/900'
};

const btnTextColors: BtnColors = {
    stroke: 'black',
    'stroke-white': 'white',
    'fill-white': 'Content-04',
    'fill-black': 'white',
    'fill-blue': 'white',
    'fill-bg-dark': 'white'
};

export const Container = styled.div<Required<Pick<RectLinkProps, 'type'>>>`
    text-align: center;
    ${({ type }) => buttonStyle(type === 'stroke' || type === 'stroke-white', false, btnBgColors[type], btnTextColors[type])}
`;

export const ModalBtnContainer = styled.div<Required<Pick<RectLinkProps, 'type'>>>`
    text-align: center;

    ${ModalBtn} {
        ${({ type }) => buttonStyle(type === 'stroke' || type === 'stroke-white', false, btnBgColors[type], btnTextColors[type])};
    }
`;
