import { color, font, offset, sideOffsets } from '@/style/mixins';
import styled, { css } from 'styled-components';
import { AlertProps } from './Alert';
import { BaseOffsetsUI } from '@/interfaces';

export const Container = styled.div<BaseOffsetsUI & { hasSideOffsets?: boolean }>`
    position: relative;
    ${({ hasSideOffsets }) => hasSideOffsets && sideOffsets('padding')}
    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')}
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')}
    ${font('h3')}
`;

export const Title = styled.h2<Required<Pick<AlertProps, 'type'>>>`
    display: flex;
    align-items: flex-start;
    color: ${({ type }) => {
        switch (type) {
            case 'success':
                return css`
                    ${color('green')}
                `;
            case 'error':
                return css`
                    ${color('red2')}
                `;
        }
    }};

    svg {
        display: block;
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;
        fill: currentColor;
    }
`;

export const TitleText = styled.span`
    ${font('h3')};

    * + & {
        ${offset('xs', 'margin-left')};
    }
`;

export const Description = styled.div`
    ${font('t1-body-sb')};

    * + & {
        ${offset('xs', 'margin-top')};
    }

    && {
        p + p {
            margin-top: 0.7em;
        }
    }
`;

export const Controls = styled.div`
    display: flex;

    * + & {
        ${offset('m', 'padding-top')};
    }

    & > * + * {
        ${offset('xs', 'margin-left')};
    }
`;
