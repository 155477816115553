import styled from 'styled-components';
import { color, font, offset, hover } from '@/style/mixins';
import { BaseOutsideOffsetsUI } from '@/interfaces';
import variables from '@/style/variables';

export const Container = styled.div<BaseOutsideOffsetsUI>`
    width: 100%;

    ${({ outsideTOffset }) => outsideTOffset && offset(outsideTOffset, 'margin-top')};
    ${({ outsideBOffset }) => outsideBOffset && offset(outsideBOffset, 'margin-bottom')};

    input.hide {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
`;

export const Label = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
`;

export const LabelText = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${color('ui-bg', 0.5)};
    border: 1px dashed ${color('divider-onbg', 0.5)};
    border-radius: ${variables.corners.smallUi};
    color: ${color('text-secondary')};
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    ${font('text2-sb')};
    ${offset('xs', 'padding')};

    ${hover(`
        opacity: 1;
        color: ${color('text-primary')}; 
        background-color: ${color('ui-bg-hover')};
        svg.iconList {
            fill: ${color('Content-04')};
        }
    `)}

    &._hidden {
        display: none;
    }

    svg {
        display: block;
        flex-shrink: 0;

        &.iconList {
            width: 16px;
            height: 16px;
            fill: ${color('ui-icon-default')};
            transition: fill 0.3s ease-in-out;
        }
    }
`;

export const BtnDelete = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 0;
    color: currentColor;
    background-color: transparent;
    transition: color 0.3s;
    cursor: pointer;
    margin-left: 12px;

    svg {
        display: block;
        pointer-events: none;
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
`;

export const FileListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed ${color('divider-onbg')};
    border-radius: ${variables.corners.smallUi};
    color: ${color('text-primary')};
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    ${font('text2-sb')};
    ${offset('xs', 'padding')};
    word-break: break-all;

    & > span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`;

export const FilesContainer = styled.div`
    * + & {
        ${offset('xxs', 'margin-top')};
    }
`;

export const FileList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;

    ${FileListItem} + ${FileListItem} {
        margin-top: 12px;
    }
`;

export const Message = styled.div`
    margin-top: 4px;
    ${font('text4')};
    color: ${color('red')};
`;

export const Descr = styled.div`
    margin-top: 12px;
    color: ${color('text-tertiary')};
    ${font('text4')};
`;
