import dynamic from 'next/dynamic';
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Container, HeaderLogin, HeaderLogo, HeaderMenu, HeaderSandwichBtn, HeaderInnerWrapper } from './Header.styled';
import LogoWhite from '@/icons/logo-header-white.svg';
import LogoBlack from '@/icons/logo-header-black.svg';
import LogoEnWhite from '@/icons/logo-en-header-white.svg';
import LogoEnBlack from '@/icons/logo-en-header-black.svg';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useTranslation from '@/hooks/useTranslation';
import { MenuILink, LinkI } from '@/interfaces';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { setSandwich, switchSandwich } from '@/redux/sandwich/sandwichSlice';
import { setModal } from '@/redux/modal/modalSlice';
import { sendMetrik } from '@/helpers';
import { HeaderLang } from './HeaderLang/HeaderLang';
import HeaderSearch, { HeaderSearchType } from '@/componentsNew/blocks/HeaderSearch/HeaderSearch';
import PotentiallyLink from '../PotentiallyLink/PotentiallyLink';
import useImportantMessageHeight from '@/hooks/useImportantMessageHeight';

const HeaderLinks = dynamic(() => import('../../blocks/HeaderLinks/HeaderLinks'));
const ModalWrapper = dynamic(() => import('../../common/ModalWrapper/ModalWrapper'));
const ModalEnter = dynamic(() => import('../../blocks/ModalEnter/ModalEnter'));

const mapStateToProps = ({ sandwich }: RootState) => ({
    sandwich
});
const mapDispatchToProps = { setSandwich, switchSandwich, setModal };
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface HeaderProps {
    isTablet?: boolean;
    isFirstBgFill?: boolean;
    isDarkHeader?: boolean;
}

const getFirstPathItem = (pathname?: string) => pathname?.slice(1).split('/')[0];

const Header = React.forwardRef(
    (
        { isTablet, sandwich, setSandwich, switchSandwich, setModal, isDarkHeader }: HeaderProps & PropsFromRedux,
        forwardedRef: ForwardedRef<HTMLDivElement | null>
    ) => {
        const { asPath, locale } = useRouter();
        const pathname = asPath.split('?')[0];
        const { t } = useTranslation('common');
        const [menu] = useState<MenuILink[] | []>(() => t('menu', { returnObjects: true }));
        const [searchText] = useState<HeaderSearchType>(() => t('search', { returnObjects: true }));
        const refContainer = useRef<HTMLDivElement | null>(null);
        const [currentLink, setCurrentLink] = useState<MenuILink | null>(null);
        const layOutOffset = useImportantMessageHeight();

        useImperativeHandle(forwardedRef, () => refContainer.current as HTMLDivElement);

        useEffect(() => {
            if (!isTablet) setSandwich(false);
        }, [isTablet, setSandwich]);

        useEffect(() => {
            setSandwich(false);
        }, [pathname]);

        useEffect(() => {
            if (!refContainer.current) return;
            refContainer.current?.classList[sandwich ? 'add' : 'remove']('_openSandwich');
            document.body.classList[sandwich ? 'add' : 'remove']('sandwich-open');
            document.documentElement.classList[sandwich ? 'add' : 'remove']('sandwich-open');
        }, [sandwich]);

        useEffect(() => {
            if (!isTablet) {
                const firstPathItem = getFirstPathItem(pathname);
                let currentLink: MenuILink | null = null;

                const hasCurrentLink = (links: LinkI[]): boolean => {
                    return links.some((link) => {
                        const linkFirstPathItem = getFirstPathItem(link?.href);
                        if (link.href && (link.href === pathname || firstPathItem === linkFirstPathItem)) {
                            return true;
                        } else if (link.subLinks) {
                            return hasCurrentLink(link.subLinks);
                        } else {
                            return false;
                        }
                    });
                };

                if (firstPathItem === '') {
                    currentLink = menu[0];
                } else if (firstPathItem === 'org' || firstPathItem === 'organizators') {
                    currentLink = menu[1];
                } else if (menu.some((link) => link.href === pathname || link.id === pathname)) {
                    currentLink = menu.filter((link) => link.href === pathname || link.id === pathname)[0];
                } else if (menu.some((link) => firstPathItem === getFirstPathItem(link?.href) || firstPathItem === link?.id)) {
                    currentLink = menu.filter((link) => firstPathItem === getFirstPathItem(link?.href) || firstPathItem === link?.id)[0];
                } else {
                    for (let link of menu) {
                        currentLink = link.subLinks && hasCurrentLink(link.subLinks) ? link : null;
                        if (currentLink) break;
                    }
                }

                if (!currentLink) {
                    currentLink = menu[0];
                }
                setCurrentLink(currentLink);
            }
        }, [menu, isTablet, pathname]);

        return (
            <Container ref={refContainer} className={`${!!isDarkHeader ? '_dark' : ''}`} layOutOffset={layOutOffset}>
                {pathname === '/' ? (
                    <HeaderLogo>
                        {locale === 'ru' ? (
                            isDarkHeader || sandwich ? (
                                <LogoWhite />
                            ) : (
                                <LogoBlack />
                            )
                        ) : isDarkHeader || sandwich ? (
                            <LogoEnWhite />
                        ) : (
                            <LogoEnBlack />
                        )}
                    </HeaderLogo>
                ) : (
                    <Link href={'/'} passHref prefetch={false}>
                        <HeaderLogo
                            onClick={() => {
                                sendMetrik('menu-click-logo', 'menu', { 'click-logo': 'logo' });
                            }}
                        >
                            {locale === 'ru' ? (
                                isDarkHeader || sandwich ? (
                                    <LogoWhite />
                                ) : (
                                    <LogoBlack />
                                )
                            ) : isDarkHeader || sandwich ? (
                                <LogoEnWhite />
                            ) : (
                                <LogoEnBlack />
                            )}
                        </HeaderLogo>
                    </Link>
                )}

                <HeaderInnerWrapper>
                    {menu.length && (
                        <HeaderMenu as={'ul'}>
                            {menu?.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        sendMetrik('menu-click-category', 'menu', { 'menu-1lvl': item.text });
                                    }}
                                >
                                    <span className={item === currentLink ? 'current' : undefined}>
                                        {PotentiallyLink({
                                            text: item.text,
                                            href: item.href ? item.href : undefined
                                        })}
                                    </span>
                                </li>
                            ))}
                        </HeaderMenu>
                    )}

                    <HeaderLang />
                    <HeaderSearch textContent={searchText} />
                </HeaderInnerWrapper>

                <HeaderLogin
                    onClick={() => {
                        setModal({ id: 'modalEnter', open: true });
                        sendMetrik('menu-click-sign-in', 'auth', {});
                    }}
                >
                    {t('login')}
                </HeaderLogin>

                {isTablet && <HeaderSandwichBtn onClick={switchSandwich as unknown as React.MouseEventHandler<HTMLButtonElement>} />}

                {currentLink?.subLinks && (
                    <HeaderMenu>
                        <HeaderLinks menu={currentLink.subLinks} parent={refContainer.current} firstMenuValue={currentLink.text} />
                    </HeaderMenu>
                )}
                <ModalWrapper id={'modalEnter'} title={t('modalEnter.title')} description={t('modalEnter.description')} bgColor="Bg-Light">
                    <ModalEnter
                        sections={t('modalEnter.sections', { returnObjects: true })}
                        description={t('modalEnter.detailedDescription')}
                    />
                </ModalWrapper>
            </Container>
        );
    }
);

Header.displayName = 'Header';

export default connector(Header);
