import React, { ForwardedRef } from 'react';
import { Container } from './InputSelect.styled';

interface optionsI {
    value: string;
    label: string;
}

export interface InputSelectProps {
    options: (
        | optionsI
        | {
              label: string;
              options: optionsI[];
          }
    )[];
    placeholder?: string | null | boolean | number;
    onChange?: (value: any) => void;
    onChangeSelect?: (value: any) => void;
    onBlur?: () => void;
    onBlurSelect?: () => void;
    isClearable?: boolean;
    isMulti?: boolean;
    isSearchable?: boolean;
    noOptionsMessage?: string;
}

// [
//     {
//         label: "Group 1",
//         options: [
//             { label: "Group 1, option 1", value: "value_1" },
//             { label: "Group 1, option 2", value: "value_2" }
//         ]
//     },
//     { label: "A root option", value: "value_3" },
//     { label: "Another root option", value: "value_4" }
// ]

const InputSelect = React.forwardRef(
    (
        {
            options,
            placeholder,
            isClearable,
            onBlur,
            onBlurSelect,
            onChange,
            onChangeSelect,
            isMulti = false,
            isSearchable = false,
            noOptionsMessage,
            ...rest
        }: InputSelectProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <Container
                isSearchable={isSearchable}
                inputRef={ref}
                options={options}
                defaultValue={options[0]}
                classNamePrefix="InputSelect"
                placeholder={placeholder}
                onChange={(value: optionsI | null) => {
                    onChange && onChange(value);
                    onChangeSelect && onChangeSelect(value);
                }}
                onBlur={() => {
                    onBlur && onBlur!();
                    onBlurSelect && onBlurSelect!();
                }}
                isClearable={isClearable}
                isMulti={isMulti}
                formatGroupLabel={(data: any) => <div className={'InputSelect__group-label'}>{data.label}</div>}
                noOptionsMessage={() => <div>{noOptionsMessage}</div>}
                {...rest}
            />
        );
    }
);

InputSelect.displayName = 'InputSelect';

export default InputSelect;
