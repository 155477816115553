import { TFunction } from 'react-i18next';
import { trimPhone } from './trimPhone';

export const NAME_INPUT_MAX_LENGTH = 125;

const configRegExp = {
    mailRegExp: {
        // value: /^[\w-.]{1,64}@([\w-]{1,251}\.)+[\w-]{2,4}$/,
        value: /^[\w-.]{1,64}@([\w-]{1,253}\.)+[\w-]{2,64}$/,
        message: 'wrongMail'
    },
    messageRegExp: {
        value: /^[\wа-яА-Я.\-—“”"?, ]{0,500}$/,
        message: 'wrongSymbols'
    },
    nameRegExp: {
        value: /^[\wа-яА-Я.\-—“”", ]{0,250}$/,
        message: 'wrongFormat'
    },
    cityRegExp: {
        value: /^[\wа-яА-Я.\-—“”", ]{0,150}$/,
        message: 'wrongFormat'
    },
    innRegExp: {
        value: /^.{6,20}$/,
        message: 'minMaxInn'
    },
    anotherRegExp: {
        value: /^(?!\s*$).+$/,
        message: 'wrongSymbols'
    },
    kppRegExp: {
        value: /^\d{9}$/,
        message: 'minMaxInn'
    },
    personalAccountExp: {
        value: /^\d{10}$/,
        message: 'minMaxInn'
    },
    siteRegExp: {
        // value: /^[a-zA-Z.\-—_ ]{0,100}$/,
        value: /^.{0,100}$/,
        message: 'wrongFormat'
    },
    phoneRegExp: {
        ru: /^\+(7)\s\((\d{3})\) \d{3} \d{2} \d{2}$/,
        bel: /^\+(375)\s\((\d{2})\) \d{3} \d{4}$/,
        message: 'wrongPhone'
    },
    birthdayRegExp: {
        value: /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
        message: 'Неверно указана дата'
    },
    passwordRegExp: {
        value: /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\s])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\s]{8,32}$/,
        message: 'Недопустимый пароль'
    },
    linkUrl: {
        value: /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&amp;%@!\-\/]))?$/,
        message: 'wrongFormat'
    },
    linkUrlSeveral: {
        value: /^((ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&amp;%@!\-\/]))?[,]?[ ]*)+$/,
        message: 'wrongFormat'
    }
};

export default configRegExp;

export const configRules = {
    mailRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.mailRegExp.value,
            message: t('wrongFormat')
        },
        setValueAs: (value: string) => value.trim()
    }),
    fullNameRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.nameRegExp.value,
            message: t('wrongFormat')
        },
        maxLength: {
            value: 250,
            message: t('maxLength')
        },
        setValueAs: (value: string) => value.trim()
    }),
    linkUrlRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.linkUrl.value,
            message: t('wrongFormat')
        },
        maxLength: {
            value: 250,
            message: t('maxLength')
        },
        setValueAs: (value: string) => value.trim()
    }),
    linkUrlRulesSeveral: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.linkUrlSeveral.value,
            message: t('wrongSymbols')
        },
        maxLength: {
            value: 500,
            message: t('maxLength')
        },
        setValueAs: (value: string) => value.trim()
    }),
    cityRegionRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.cityRegExp.value,
            message: t('wrongFormat')
        },
        maxLength: {
            value: 150,
            message: t('maxLength')
        },
        setValueAs: (value: string) => value.trim()
    }),
    siteRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.siteRegExp.value,
            message: t('wrongFormat')
        },
        maxLength: {
            value: 100,
            message: t('maxLength')
        }
    }),
    messageRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.messageRegExp.value,
            message: t('wrongSymbols')
        },
        maxLength: {
            value: 500,
            message: t('maxLength')
        },
        setValueAs: (value: string) => value.trim()
    }),
    messageTopicLessonRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredTopicLessonMessage') : false,
        pattern: {
            value: configRegExp.messageRegExp.value,
            message: t('wrongSymbols')
        },
        maxLength: {
            value: 500,
            message: t('maxLength')
        }
    }),
    anotherRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.anotherRegExp.value,
            message: t('wrongSymbols')
        }
    }),
    innRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.innRegExp.value,
            message: t('minMaxInn')
        },
        minLength: {
            value: 6,
            message: `${t('minMaxInn')}`
        },
        maxLength: {
            value: 20,
            message: `${t('minMaxInn')}`
        }
    }),
    kppRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.kppRegExp.value,
            message: `${t('allowedCountMessage')} 9`
        },
        minLength: {
            value: 9,
            message: `${t('allowedCountMessage')} 9`
        },
        maxLength: {
            value: 9,
            message: `${t('allowedCountMessage')} 9`
        }
    }),
    personalAccountRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false,
        pattern: {
            value: configRegExp.personalAccountExp.value,
            message: `${t('allowedCountMessage')} 10`
        },
        minLength: {
            value: 10,
            message: `${t('allowedCountMessage')} 10`
        },
        maxLength: {
            value: 10,
            message: `${t('allowedCountMessage')} 10`
        }
    }),
    phoneRules:
        (t: TFunction<string, any>, required: boolean = true) =>
        (formatLang: 'ru' | 'bel') => ({
            required: required ? t('requiredMessage') : false,
            pattern: {
                value: configRegExp.phoneRegExp[formatLang],
                message: t('wrongFormat')
            }
        }),
    phoneRulesWithoutFormat:
        (t: TFunction<string, any>, required: boolean = true) =>
        (formatLang: string) => ({
            required: required ? t('requiredMessage') : false,
            validate: (value: string) => {
                const isPhoneEmpty = trimPhone(value).length <= formatLang.length;

                return !isPhoneEmpty || t('requiredMessage');
            }
        }),
    simpleSelectRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false
    }),
    topicLessonSelectRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredTopicLessonSelectMessage') : false
    }),
    fileRules: (t: TFunction<string, any>, required: boolean = true) => ({
        required: required ? t('requiredMessage') : false
    })
};

export const PHONE_FORMAT: { [key: string]: string } = {
    ru: '+7 (###) ### ## ##',
    bel: '+375 (##) ### ####',

    // Afghanistan
    ['+93']: '+93 ### ### ## ## ### ###',

    // Albania
    ['+355']: '+355 ### ### ## ## ### ###',

    // Algeria
    ['+21']: '+21 ### ### ## ## ### ###',

    // American Samoa
    ['+684']: '+684 ### ### ## ## ### ###',

    // Andorra
    ['+376']: '+376 ### ### ## ## ### ###',

    // Angola
    ['+244']: '+244 ### ### ## ## ### ###',

    // Anguilla
    ['+1-264']: '+1-264 ### ### ## ## ### ###',

    // Antigua and Barbuda
    ['+1-268']: '+1-268 ### ### ## ## ### ###',

    // Armenia
    ['+374']: '+374 ### ### ## ## ### ###',

    // Argentina
    ['+54']: '+54 ### ### ## ## ### ###',

    // Australia
    ['+61']: '+61 ### ### ## ## ### ###',

    // Austria
    ['+43']: '+43 ### ### ## ## ### ###',

    // Azerbaijan
    ['+994']: '+994 ### ### ## ## ### ###',

    // Bahamas
    ['+1-242']: '+1-242 ### ### ## ## ### ###',

    // Bahrain
    ['+973']: '+973 ### ### ## ## ### ###',

    // Bangladesh
    ['+880']: '+880 ### ### ## ## ### ###',

    // Barbados
    ['+1-246']: '+1-246 ### ### ## ## ### ###',

    // Belarus
    ['+375']: '+375 (##) ### ####',

    // Belgium
    ['+32']: '+32 ### ### ## ## ### ###',

    // Belize
    ['+501']: '+501 ### ### ## ## ### ###',

    // Benin
    ['+229']: '+229 ### ### ## ## ### ###',

    // Bermuda
    ['+1-441']: '+1-441 ### ### ## ## ### ###',

    // Bolivia
    ['+591']: '+591 ### ### ## ## ### ###',

    // Bosnia and Herzegovina
    ['+387']: '+387 ### ### ## ## ### ###',

    // Botswana
    ['+267']: '+267 ### ### ## ## ### ###',

    // Brazil
    ['+55']: '+55 ### ### ## ## ### ###',

    // British Virgin Islands
    ['+1-284']: '+1-284 ### ### ## ## ### ###',

    // Brunei Darusalaam
    ['+673']: '+673 ### ### ## ## ### ###',

    // Bulgaria
    ['+359']: '+359 ### ### ## ## ### ###',

    // Burkina Faso
    ['+226']: '+226 ### ### ## ## ### ###',

    // Burundi
    ['+257']: '+257 ### ### ## ## ### ###',

    // Byelorussian
    ['+7B']: '+7 (###) ### ## ##',

    // Cambodia
    ['+855']: '+855 ### ### ## ## ### ###',

    // Cameroon
    ['+237']: '+237 ### ### ## ## ### ###',

    // Canada
    ['+1C']: '+1 ### ### ## ## ### ###',

    // Cape Verde
    ['+238']: '+238 ### ### ## ## ### ###',

    // Cayman Islands
    ['+1-345']: '+1-345 ### ### ## ## ### ###',

    // Central African Republic
    ['+236']: '+236 ### ### ## ## ### ###',

    // Chad
    ['+235']: '+235 ### ### ## ## ### ###',

    // Chile
    ['+56']: '+56 ### ### ## ## ### ###',

    // China
    ['+86']: '+86 ### ### ## ## ### ###',

    // Christmas Island
    ['+61CI']: '+61 ### ### ## ## ### ###',

    // Cocos Islands
    ['+61-891']: '+61-891 ### ### ## ## ### ###',

    // Colombia
    ['+57']: '+57 ### ### ## ## ### ###',

    // Commonwealth of the Northern Mariana Islands
    ['+1-670']: '+1-670 ### ### ## ## ### ###',

    // Comoros and Mayotte Island
    ['+269']: '+269 ### ### ## ## ### ###',

    // Congo
    ['+242']: '+242 ### ### ## ## ### ###',

    // Cook Islands
    ['+682']: '+682 ### ### ## ## ### ###',

    // Costa Rica
    ['+506']: '+506 ### ### ## ## ### ###',

    // Croatia
    ['+385']: '+385 ### ### ## ## ### ###',

    // Cuba
    ['+53']: '+53 ### ### ## ## ### ###',

    // Cyprus
    ['+357']: '+357 ### ### ## ## ### ###',

    // Czech Republic
    ['+420']: '+420 ### ### ## ## ### ###',

    // Denmark
    ['+45']: '+45 ### ### ## ## ### ###',

    // Diego Garcia
    ['+246']: '+246 ### ### ## ## ### ###',

    // Djibouti
    ['+253']: '+253 ### ### ## ## ### ###',

    // Dominica
    ['+1-767']: '+1-767 ### ### ## ## ### ###',

    // Dominican Republic
    ['+1-809']: '+1-809 ### ### ## ## ### ###',
    ['+1-829']: '+1-829 ### ### ## ## ### ###',
    ['+1-849']: '+1-849 ### ### ## ## ### ###',

    // East Timor
    ['+62']: '+62 ### ### ## ## ### ###',

    // Ecuador
    ['+593']: '+593 ### ### ## ## ### ###',

    // Egypt
    ['+20']: '+20 ### ### ## ## ### ###',

    // El Salvador
    ['+503']: '+503 ### ### ## ## ### ###',

    // Equatorial Guinea
    ['+240']: '+240 ### ### ## ## ### ###',

    // Estonia
    ['+372']: '+372 ### ### ## ## ### ###',

    // Ethiopia
    ['+251']: '+251 ### ### ## ## ### ###',

    // Faeroe Islands
    ['+298']: '+298 ### ### ## ## ### ###',

    // Falkland Islands
    ['+500']: '+500 ### ### ## ## ### ###',

    // Fiji
    ['+679']: '+679 ### ### ## ## ### ###',

    // Finland
    ['+358']: '+358 ### ### ## ## ### ###',

    // France
    ['+33']: '+33 ### ### ## ## ### ###',

    // French Antilles
    ['+590']: '+590 ### ### ## ## ### ###',

    // French Guiana
    ['+594']: '+594 ### ### ## ## ### ###',

    // French Polynesia
    ['+689']: '+689 ### ### ## ## ### ###',

    // Gabon
    ['+241']: '+241 ### ### ## ## ### ###',

    // Gambia
    ['+220']: '+220 ### ### ## ## ### ###',

    // Georgia
    ['+995']: '+995 ### ### ## ## ### ###',

    // Germany
    ['+49']: '+49 ### ### ## ## ### ###',

    // Ghana
    ['+233']: '+233 ### ### ## ## ### ###',

    // Gibraltar
    ['+350']: '+350 ### ### ## ## ### ###',

    // Greece
    ['+30']: '+30 ### ### ## ## ### ###',

    // Greenland
    ['+299']: '+299 ### ### ## ## ### ###',

    // Grenada
    ['+1-473']: '+1-473 ### ### ## ## ### ###',

    // Guam
    ['+1-671']: '+1-671 ### ### ## ## ### ###',

    // Guatemala
    ['+502']: '+502 ### ### ## ## ### ###',

    // Guinea
    ['+224']: '+224 ### ### ## ## ### ###',

    // Guinea-Bissau
    ['+245']: '+245 ### ### ## ## ### ###',

    // Guyana
    ['+592']: '+592 ### ### ## ## ### ###',

    // Haiti
    ['+509']: '+509 ### ### ## ## ### ###',

    // Honduras
    ['+504']: '+504 ### ### ## ## ### ###',

    // Hong Kong
    ['+852']: '+852 ### ### ## ## ### ###',

    // Hungary
    ['+36']: '+36 ### ### ## ## ### ###',

    // Iceland
    ['+354']: '+354 ### ### ## ## ### ###',

    // India
    ['+91']: '+91 ### ### ## ## ### ###',

    // Indonesia
    ['+62I']: '+62 ### ### ## ## ### ###',

    // Iran
    ['+98']: '+98 ### ### ## ## ### ###',

    // Iraq
    ['+964']: '+964 ### ### ## ## ### ###',

    // Irish Republic
    ['+353']: '+353 ### ### ## ## ### ###',

    // Israel
    ['+972']: '+972 ### ### ## ## ### ###',

    // Italy
    ['+39']: '+39 ### ### ## ## ### ###',

    // Ivory Coast
    ['+225']: '+225 ### ### ## ## ### ###',

    // Jamaica
    ['+1-876']: '+1-876 ### ### ## ## ### ###',

    // Japan
    ['+81']: '+81 ### ### ## ## ### ###',

    // Jordan
    ['+962']: '+962 ### ### ## ## ### ###',

    // Kazakhstan
    ['+7K']: '+7 ### ### ## ## ### ###',

    // Kenya
    ['+254']: '+254 ### ### ## ## ### ###',

    // Kiribati Republic
    ['+686']: '+686 ### ### ## ## ### ###',

    // Kirg(h)izia
    ['+996']: '+996 ### ### ## ## ### ###',

    // Kuwait
    ['+965']: '+965 ### ### ## ## ### ###',

    // Laos
    ['+856']: '+856 ### ### ## ## ### ###',

    // Latvia
    ['+371']: '+371 ### ### ## ## ### ###',

    // Lebanon
    ['+961']: '+961 ### ### ## ## ### ###',

    // Lesotho
    ['+266']: '+266 ### ### ## ## ### ###',

    // Liberia
    ['+231']: '+231 ### ### ## ## ### ###',

    // Libya
    ['+21L']: '+21 ### ### ## ## ### ###',

    // Liechtenstein
    ['+41']: '+41 ### ### ## ## ### ###',

    // Lithuania
    ['+370']: '+370 ### ### ## ## ### ###',

    // Luxembourg
    ['+352']: '+352 ### ### ## ## ### ###',

    // Macao
    ['+853']: '+853 ### ### ## ## ### ###',

    // Macedonia
    ['+389']: '+389 ### ### ## ## ### ###',

    // Madagascar
    ['+261']: '+261 ### ### ## ## ### ###',

    // Malawi
    ['+265']: '+265 ### ### ## ## ### ###',

    // Malaysia
    ['+60']: '+60 ### ### ## ## ### ###',

    // Maldives
    ['+960']: '+960 ### ### ## ## ### ###',

    // Mali
    ['+223']: '+223 ### ### ## ## ### ###',

    // Malta
    ['+356']: '+356 ### ### ## ## ### ###',

    // Marshall Islands
    ['+692']: '+692 ### ### ## ## ### ###',

    // Martinique
    ['+596']: '+596 ### ### ## ## ### ###',

    // Mauritania
    ['+222']: '+222 ### ### ## ## ### ###',

    // Mauritius
    ['+230']: '+230 ### ### ## ## ### ###',

    // Mexico
    ['+52']: '+52 ### ### ## ## ### ###',

    // Micronesia
    ['+691']: '+691 ### ### ## ## ### ###',

    // Moldova
    ['+373']: '+373 ### ### ## ## ### ###',

    // Monaco
    ['+377']: '+377 ### ### ## ## ### ###',

    // Mongolia
    ['+976']: '+976 ### ### ## ## ### ###',

    // Montserrat
    ['+1-664']: '+1-664 ### ### ## ## ### ###',

    // Morocco
    ['+212']: '+212 ### ### ## ## ### ###',

    // Mozambique
    ['+258']: '+258 ### ### ## ## ### ###',

    // Myanmar
    ['+95']: '+95 ### ### ## ## ### ###',

    // Namibia
    ['+264']: '+264 ### ### ## ## ### ###',

    // Nauru
    ['+674']: '+674 ### ### ## ## ### ###',

    // Nepal
    ['+977']: '+977 ### ### ## ## ### ###',

    // Netherlands
    ['+31']: '+31 ### ### ## ## ### ###',

    // Netherlands Antilles
    ['+599']: '+599 ### ### ## ## ### ###',

    // New Caledonia
    ['+687']: '+687 ### ### ## ## ### ###',

    // New Zealand
    ['+64']: '+64 ### ### ## ## ### ###',

    // Nicaragua
    ['+505']: '+505 ### ### ## ## ### ###',

    // Niger
    ['+227']: '+227 ### ### ## ## ### ###',

    // Nigeria
    ['+234']: '+234 ### ### ## ## ### ###',

    // Niue
    ['+683']: '+683 ### ### ## ## ### ###',

    // Norfolk Island
    ['+672']: '+672 ### ### ## ## ### ###',

    // North Korea
    ['+850']: '+850 ### ### ## ## ### ###',

    // North Yemen
    ['+967']: '+967 ### ### ## ## ### ###',

    // Northern Mariana Islands
    ['+670']: '+670 ### ### ## ## ### ###',

    // Norway
    ['+47']: '+47 ### ### ## ## ### ###',

    // Oman
    ['+968']: '+968 ### ### ## ## ### ###',

    // Pakistan
    ['+92']: '+92 ### ### ## ## ### ###',

    // Panama
    ['+507']: '+507 ### ### ## ## ### ###',

    // Papua New Guinea
    ['+675']: '+675 ### ### ## ## ### ###',

    // Paraguay
    ['+595']: '+595 ### ### ## ## ### ###',

    // Peru
    ['+51']: '+51 ### ### ## ## ### ###',

    // Philippines
    ['+63']: '+63 ### ### ## ## ### ###',

    // Poland
    ['+48']: '+48 ### ### ## ## ### ###',

    // Portugal
    ['+351']: '+351 ### ### ## ## ### ###',

    // Puerto Rico
    ['+1-787']: '+1-787  ### ### ## ## ### ###',
    ['+1-939']: '+1-939 ### ### ## ## ### ###',

    // Qatar
    ['+974']: '+974 ### ### ## ## ### ###',

    // Republic of San Marino
    ['+378']: '+378 ### ### ## ## ### ###',

    // Reunion
    ['+262']: '+262 ### ### ## ## ### ###',

    // Romania
    ['+40']: '+40 ### ### ## ## ### ###',

    // Russia
    ['+7']: '+7 (###) ### ## ##',

    // Rwandese Republic
    ['+250']: '+250 ### ### ## ## ### ###',

    // Saint Helena and Ascension Island
    ['+290']: '+290  ### ### ## ## ### ###',
    ['+247']: '+247 ### ### ## ## ### ###',

    // Saint Pierre et Miquelon
    ['+508']: '+508 ### ### ## ## ### ###',

    // San Marino
    ['+39SM']: '+39 ### ### ## ## ### ###',

    // Sao Tome e Principe
    ['+239']: '+239 ### ### ## ## ### ###',

    // Saudi Arabia
    ['+966']: '+966 ### ### ## ## ### ###',

    // Senegal
    ['+221']: '+221 ### ### ## ## ### ###',

    // Seychelles
    ['+248']: '+248 ### ### ## ## ### ###',

    // Sierra Leone
    ['+232']: '+232 ### ### ## ## ### ###',

    // Singapore
    ['+65']: '+65 ### ### ## ## ### ###',

    // Slovakia
    ['+421']: '+421 ### ### ## ## ### ###',

    // Slovenia
    ['+386']: '+386 ### ### ## ## ### ###',

    // Solomon Islands
    ['+677']: '+677 ### ### ## ## ### ###',

    // Somalia
    ['+252']: '+252 ### ### ## ## ### ###',

    // South Africa
    ['+27']: '+27 ### ### ## ## ### ###',

    // South Korea
    ['+82']: '+82 ### ### ## ## ### ###',

    // South Yemen
    ['+969']: '+969 ### ### ## ## ### ###',

    // Spain
    ['+34']: '+34 ### ### ## ## ### ###',

    // Sri Lanka
    ['+94']: '+94 ### ### ## ## ### ###',

    // St.Kitts and Nevis
    ['+1-869']: '+1-869 ### ### ## ## ### ###',

    // St.Lucia
    ['+1-758']: '+1-758 ### ### ## ## ### ###',

    // St.Vincent and the Grenadines
    ['+1-784']: '+1-784 ### ### ## ## ### ###',

    // Sudan
    ['+249']: '+249 ### ### ## ## ### ###',

    // Suriname
    ['+597']: '+597 ### ### ## ## ### ###',

    // Svalbard and Jan Mayen Islands
    ['+47SJ']: '+47 ### ### ## ## ### ###',

    // Swaziland
    ['+268']: '+268 ### ### ## ## ### ###',

    // Sweden
    ['+46']: '+46 ### ### ## ## ### ###',

    // Switzerland
    ['+41S']: '+41 ### ### ## ## ### ###',

    // Syria
    ['+963']: '+963 ### ### ## ## ### ###',

    // Ta(d)jikistan
    ['+992']: '+992 ### ### ## ## ### ###',

    // Taiwan
    ['+886']: '+886 ### ### ## ## ### ###',

    // Tanzania
    ['+255']: '+255 ### ### ## ## ### ###',

    // Thailand
    ['+66']: '+66 ### ### ## ## ### ###',

    // Togolese Republic
    ['+228']: '+228 ### ### ## ## ### ###',

    // Tokelau
    ['+690']: '+690 ### ### ## ## ### ###',

    // Tonga
    ['+676']: '+676 ### ### ## ## ### ###',

    // Trinidad and Tobago
    ['+1-868']: '+1-868 ### ### ## ## ### ###',

    // Tunisia
    ['+21T']: '+21 ### ### ## ## ### ###',

    // Turkey
    ['+90']: '+90 ### ### ## ## ### ###',

    // Turkmenistan
    ['+993']: '+993 ### ### ## ## ### ###',

    // Turks & Caicos Islands
    ['+1-649']: '+1-649 ### ### ## ## ### ###',

    // Tuvalu
    ['+688']: '+688 ### ### ## ## ### ###',

    // US Virgin Islands
    ['+1-340']: '+1-340 ### ### ## ## ### ###',

    // Uganda
    ['+256']: '+256 ### ### ## ## ### ###',

    // Ukraine
    ['+380']: '+380 ### ### ## ## ### ###',

    // United Arab Emirates
    ['+971']: '+971 ### ### ## ## ### ###',

    // United Kingdom
    ['+44']: '+44 ### ### ## ## ### ###',

    // Uruguay
    ['+598']: '+598 ### ### ## ## ### ###',

    // USA
    ['+1']: '+1 ### ### ## ## ### ###',

    // Uzbekistan
    ['+998']: '+998 ### ### ## ## ### ###',

    // Vanuatu
    ['+678']: '+678 ### ### ## ## ### ###',

    // Vatican City
    ['+39VC']: '+39 ### ### ## ## ### ###',

    // Venezuela
    ['+58']: '+58 ### ### ## ## ### ###',

    // Vietnam
    ['+84']: '+84 ### ### ## ## ### ###',

    // Wallis and Futuna Islands
    ['+681']: '+681 ### ### ## ## ### ###',

    // Western Sahara
    ['+21WS']: '+21 ### ### ## ## ### ###',

    // Western Samoa
    ['+685']: '+685 ### ### ## ## ### ###',

    // Yugoslavia
    ['+381']: '+381 ### ### ## ## ### ###',

    // Zaire
    ['+243']: '+243 ### ### ## ## ### ###',

    // Zambia
    ['+260']: '+260 ### ### ## ## ### ###',

    // Zimbabwe
    ['+263']: '+263 ### ### ## ## ### ###'
};

export const PHONE_FORMAT_PLACEHOLDER: { [key: string]: string } = {
    ru: '+7 (___) ___ __ __',
    bel: '+375 (__) ___ ____',

    // Afghanistan
    ['+93']: '+93 ___ ___ __ __ ___ ___',

    // Albania
    ['+355']: '+355 ___ ___ __ __ ___ ___',

    // Algeria
    ['+21']: '+21 ___ ___ __ __ ___ ___',

    // American Samoa
    ['+684']: '+684 ___ ___ __ __ ___ ___',

    // Andorra
    ['+376']: '+376 ___ ___ __ __ ___ ___',

    // Angola
    ['+244']: '+244 ___ ___ __ __ ___ ___',

    // Anguilla
    ['+1-264']: '+1-264 ___ ___ __ __ ___ ___',

    // Antigua and Barbuda
    ['+1-268']: '+1-268 ___ ___ __ __ ___ ___',

    // Armenia
    ['+374']: '+374 ___ ___ __ __ ___ ___',

    // Argentina
    ['+54']: '+54 ___ ___ __ __ ___ ___',

    // Australia
    ['+61']: '+61 ___ ___ __ __ ___ ___',

    // Austria
    ['+43']: '+43 ___ ___ __ __ ___ ___',

    // Azerbaijan
    ['+994']: '+994 ___ ___ __ __ ___ ___',

    // Bahamas
    ['+1-242']: '+1-242 ___ ___ __ __ ___ ___',

    // Bahrain
    ['+973']: '+973 ___ ___ __ __ ___ ___',

    // Bangladesh
    ['+880']: '+880 ___ ___ __ __ ___ ___',

    // Barbados
    ['+1-246']: '+1-246 ___ ___ __ __ ___ ___',

    // Belarus
    ['+375']: '+375 (__) ___ ____',

    // Belgium
    ['+32']: '+32 ___ ___ __ __ ___ ___',

    // Belize
    ['+501']: '+501 ___ ___ __ __ ___ ___',

    // Benin
    ['+229']: '+229 ___ ___ __ __ ___ ___',

    // Bermuda
    ['+1-441']: '+1-441 ___ ___ __ __ ___ ___',

    // Bolivia
    ['+591']: '+591 ___ ___ __ __ ___ ___',

    // Bosnia and Herzegovina
    ['+387']: '+387 ___ ___ __ __ ___ ___',

    // Botswana
    ['+267']: '+267 ___ ___ __ __ ___ ___',

    // Brazil
    ['+55']: '+55 ___ ___ __ __ ___ ___',

    // British Virgin Islands
    ['+1-284']: '+1-284 ___ ___ __ __ ___ ___',

    // Brunei Darusalaam
    ['+673']: '+673 ___ ___ __ __ ___ ___',

    // Bulgaria
    ['+359']: '+359 ___ ___ __ __ ___ ___',

    // Burkina Faso
    ['+226']: '+226 ___ ___ __ __ ___ ___',

    // Burundi
    ['+257']: '+257 ___ ___ __ __ ___ ___',

    // Byelorussian
    ['+7B']: '+7 (___) ___ __ __',

    // Cambodia
    ['+855']: '+855 ___ ___ __ __ ___ ___',

    // Cameroon
    ['+237']: '+237 ___ ___ __ __ ___ ___',

    // Canada
    ['+1C']: '+1 ___ ___ __ __ ___ ___',

    // Cape Verde
    ['+238']: '+238 ___ ___ __ __ ___ ___',

    // Cayman Islands
    ['+1-345']: '+1-345 ___ ___ __ __ ___ ___',

    // Central African Republic
    ['+236']: '+236 ___ ___ __ __ ___ ___',

    // Chad
    ['+235']: '+235 ___ ___ __ __ ___ ___',

    // Chile
    ['+56']: '+56 ___ ___ __ __ ___ ___',

    // China
    ['+86']: '+86 ___ ___ __ __ ___ ___',

    // Christmas Island
    ['+61CI']: '+61 ___ ___ __ __ ___ ___',

    // Cocos Islands
    ['+61-891']: '+61-891 ___ ___ __ __ ___ ___',

    // Colombia
    ['+57']: '+57 ___ ___ __ __ ___ ___',

    // Commonwealth of the Northern Mariana Islands
    ['+1-670']: '+1-670 ___ ___ __ __ ___ ___',

    // Comoros and Mayotte Island
    ['+269']: '+269 ___ ___ __ __ ___ ___',

    // Congo
    ['+242']: '+242 ___ ___ __ __ ___ ___',

    // Cook Islands
    ['+682']: '+682 ___ ___ __ __ ___ ___',

    // Costa Rica
    ['+506']: '+506 ___ ___ __ __ ___ ___',

    // Croatia
    ['+385']: '+385 ___ ___ __ __ ___ ___',

    // Cuba
    ['+53']: '+53 ___ ___ __ __ ___ ___',

    // Cyprus
    ['+357']: '+357 ___ ___ __ __ ___ ___',

    // Czech Republic
    ['+420']: '+420 ___ ___ __ __ ___ ___',

    // Denmark
    ['+45']: '+45 ___ ___ __ __ ___ ___',

    // Diego Garcia
    ['+246']: '+246 ___ ___ __ __ ___ ___',

    // Djibouti
    ['+253']: '+253 ___ ___ __ __ ___ ___',

    // Dominica
    ['+1-767']: '+1-767 ___ ___ __ __ ___ ___',

    // Dominican Republic
    ['+1-809']: '+1-809 ___ ___ __ __ ___ ___',
    ['+1-829']: '+1-829 ___ ___ __ __ ___ ___',
    ['+1-849']: '+1-849 ___ ___ __ __ ___ ___',

    // East Timor
    ['+62']: '+62 ___ ___ __ __ ___ ___',

    // Ecuador
    ['+593']: '+593 ___ ___ __ __ ___ ___',

    // Egypt
    ['+20']: '+20 ___ ___ __ __ ___ ___',

    // El Salvador
    ['+503']: '+503 ___ ___ __ __ ___ ___',

    // Equatorial Guinea
    ['+240']: '+240 ___ ___ __ __ ___ ___',

    // Estonia
    ['+372']: '+372 ___ ___ __ __ ___ ___',

    // Ethiopia
    ['+251']: '+251 ___ ___ __ __ ___ ___',

    // Faeroe Islands
    ['+298']: '+298 ___ ___ __ __ ___ ___',

    // Falkland Islands
    ['+500']: '+500 ___ ___ __ __ ___ ___',

    // Fiji
    ['+679']: '+679 ___ ___ __ __ ___ ___',

    // Finland
    ['+358']: '+358 ___ ___ __ __ ___ ___',

    // France
    ['+33']: '+33 ___ ___ __ __ ___ ___',

    // French Antilles
    ['+590']: '+590 ___ ___ __ __ ___ ___',

    // French Guiana
    ['+594']: '+594 ___ ___ __ __ ___ ___',

    // French Polynesia
    ['+689']: '+689 ___ ___ __ __ ___ ___',

    // Gabon
    ['+241']: '+241 ___ ___ __ __ ___ ___',

    // Gambia
    ['+220']: '+220 ___ ___ __ __ ___ ___',

    // Georgia
    ['+995']: '+995 ___ ___ __ __ ___ ___',

    // Germany
    ['+49']: '+49 ___ ___ __ __ ___ ___',

    // Ghana
    ['+233']: '+233 ___ ___ __ __ ___ ___',

    // Gibraltar
    ['+350']: '+350 ___ ___ __ __ ___ ___',

    // Greece
    ['+30']: '+30 ___ ___ __ __ ___ ___',

    // Greenland
    ['+299']: '+299 ___ ___ __ __ ___ ___',

    // Grenada
    ['+1-473']: '+1-473 ___ ___ __ __ ___ ___',

    // Guam
    ['+1-671']: '+1-671 ___ ___ __ __ ___ ___',

    // Guatemala
    ['+502']: '+502 ___ ___ __ __ ___ ___',

    // Guinea
    ['+224']: '+224 ___ ___ __ __ ___ ___',

    // Guinea-Bissau
    ['+245']: '+245 ___ ___ __ __ ___ ___',

    // Guyana
    ['+592']: '+592 ___ ___ __ __ ___ ___',

    // Haiti
    ['+509']: '+509 ___ ___ __ __ ___ ___',

    // Honduras
    ['+504']: '+504 ___ ___ __ __ ___ ___',

    // Hong Kong
    ['+852']: '+852 ___ ___ __ __ ___ ___',

    // Hungary
    ['+36']: '+36 ___ ___ __ __ ___ ___',

    // Iceland
    ['+354']: '+354 ___ ___ __ __ ___ ___',

    // India
    ['+91']: '+91 ___ ___ __ __ ___ ___',

    // Indonesia
    ['+62I']: '+62 ___ ___ __ __ ___ ___',

    // Iran
    ['+98']: '+98 ___ ___ __ __ ___ ___',

    // Iraq
    ['+964']: '+964 ___ ___ __ __ ___ ___',

    // Irish Republic
    ['+353']: '+353 ___ ___ __ __ ___ ___',

    // Israel
    ['+972']: '+972 ___ ___ __ __ ___ ___',

    // Italy
    ['+39']: '+39 ___ ___ __ __ ___ ___',

    // Ivory Coast
    ['+225']: '+225 ___ ___ __ __ ___ ___',

    // Jamaica
    ['+1-876']: '+1-876 ___ ___ __ __ ___ ___',

    // Japan
    ['+81']: '+81 ___ ___ __ __ ___ ___',

    // Jordan
    ['+962']: '+962 ___ ___ __ __ ___ ___',

    // Kazakhstan
    ['+7K']: '+7 ___ ___ __ __ ___ ___',

    // Kenya
    ['+254']: '+254 ___ ___ __ __ ___ ___',

    // Kiribati Republic
    ['+686']: '+686 ___ ___ __ __ ___ ___',

    // Kirg(h)izia
    ['+996']: '+996 ___ ___ __ __ ___ ___',

    // Kuwait
    ['+965']: '+965 ___ ___ __ __ ___ ___',

    // Laos
    ['+856']: '+856 ___ ___ __ __ ___ ___',

    // Latvia
    ['+371']: '+371 ___ ___ __ __ ___ ___',

    // Lebanon
    ['+961']: '+961 ___ ___ __ __ ___ ___',

    // Lesotho
    ['+266']: '+266 ___ ___ __ __ ___ ___',

    // Liberia
    ['+231']: '+231 ___ ___ __ __ ___ ___',

    // Libya
    ['+21L']: '+21 ___ ___ __ __ ___ ___',

    // Liechtenstein
    ['+41']: '+41 ___ ___ __ __ ___ ___',

    // Lithuania
    ['+370']: '+370 ___ ___ __ __ ___ ___',

    // Luxembourg
    ['+352']: '+352 ___ ___ __ __ ___ ___',

    // Macao
    ['+853']: '+853 ___ ___ __ __ ___ ___',

    // Macedonia
    ['+389']: '+389 ___ ___ __ __ ___ ___',

    // Madagascar
    ['+261']: '+261 ___ ___ __ __ ___ ___',

    // Malawi
    ['+265']: '+265 ___ ___ __ __ ___ ___',

    // Malaysia
    ['+60']: '+60 ___ ___ __ __ ___ ___',

    // Maldives
    ['+960']: '+960 ___ ___ __ __ ___ ___',

    // Mali
    ['+223']: '+223 ___ ___ __ __ ___ ___',

    // Malta
    ['+356']: '+356 ___ ___ __ __ ___ ___',

    // Marshall Islands
    ['+692']: '+692 ___ ___ __ __ ___ ___',

    // Martinique
    ['+596']: '+596 ___ ___ __ __ ___ ___',

    // Mauritania
    ['+222']: '+222 ___ ___ __ __ ___ ___',

    // Mauritius
    ['+230']: '+230 ___ ___ __ __ ___ ___',

    // Mexico
    ['+52']: '+52 ___ ___ __ __ ___ ___',

    // Micronesia
    ['+691']: '+691 ___ ___ __ __ ___ ___',

    // Moldova
    ['+373']: '+373 ___ ___ __ __ ___ ___',

    // Monaco
    ['+377']: '+377 ___ ___ __ __ ___ ___',

    // Mongolia
    ['+976']: '+976 ___ ___ __ __ ___ ___',

    // Montserrat
    ['+1-664']: '+1-664 ___ ___ __ __ ___ ___',

    // Morocco
    ['+212']: '+212 ___ ___ __ __ ___ ___',

    // Mozambique
    ['+258']: '+258 ___ ___ __ __ ___ ___',

    // Myanmar
    ['+95']: '+95 ___ ___ __ __ ___ ___',

    // Namibia
    ['+264']: '+264 ___ ___ __ __ ___ ___',

    // Nauru
    ['+674']: '+674 ___ ___ __ __ ___ ___',

    // Nepal
    ['+977']: '+977 ___ ___ __ __ ___ ___',

    // Netherlands
    ['+31']: '+31 ___ ___ __ __ ___ ___',

    // Netherlands Antilles
    ['+599']: '+599 ___ ___ __ __ ___ ___',

    // New Caledonia
    ['+687']: '+687 ___ ___ __ __ ___ ___',

    // New Zealand
    ['+64']: '+64 ___ ___ __ __ ___ ___',

    // Nicaragua
    ['+505']: '+505 ___ ___ __ __ ___ ___',

    // Niger
    ['+227']: '+227 ___ ___ __ __ ___ ___',

    // Nigeria
    ['+234']: '+234 ___ ___ __ __ ___ ___',

    // Niue
    ['+683']: '+683 ___ ___ __ __ ___ ___',

    // Norfolk Island
    ['+672']: '+672 ___ ___ __ __ ___ ___',

    // North Korea
    ['+850']: '+850 ___ ___ __ __ ___ ___',

    // North Yemen
    ['+967']: '+967 ___ ___ __ __ ___ ___',

    // Northern Mariana Islands
    ['+670']: '+670 ___ ___ __ __ ___ ___',

    // Norway
    ['+47']: '+47 ___ ___ __ __ ___ ___',

    // Oman
    ['+968']: '+968 ___ ___ __ __ ___ ___',

    // Pakistan
    ['+92']: '+92 ___ ___ __ __ ___ ___',

    // Panama
    ['+507']: '+507 ___ ___ __ __ ___ ___',

    // Papua New Guinea
    ['+675']: '+675 ___ ___ __ __ ___ ___',

    // Paraguay
    ['+595']: '+595 ___ ___ __ __ ___ ___',

    // Peru
    ['+51']: '+51 ___ ___ __ __ ___ ___',

    // Philippines
    ['+63']: '+63 ___ ___ __ __ ___ ___',

    // Poland
    ['+48']: '+48 ___ ___ __ __ ___ ___',

    // Portugal
    ['+351']: '+351 ___ ___ __ __ ___ ___',

    // Puerto Rico
    ['+1-787']: '+1-787  ___ ___ __ __ ___ ___',
    ['+1-939']: '+1-939 ___ ___ __ __ ___ ___',

    // Qatar
    ['+974']: '+974 ___ ___ __ __ ___ ___',

    // Republic of San Marino
    ['+378']: '+378 ___ ___ __ __ ___ ___',

    // Reunion
    ['+262']: '+262 ___ ___ __ __ ___ ___',

    // Romania
    ['+40']: '+40 ___ ___ __ __ ___ ___',

    // Russia
    ['+7']: '+7 (___) ___ __ __',

    // Rwandese Republic
    ['+250']: '+250 ___ ___ __ __ ___ ___',

    // Saint Helena and Ascension Island
    ['+290']: '+290  ___ ___ __ __ ___ ___',
    ['+247']: '+247 ___ ___ __ __ ___ ___',

    // Saint Pierre et Miquelon
    ['+508']: '+508 ___ ___ __ __ ___ ___',

    // San Marino
    ['+39SM']: '+39 ___ ___ __ __ ___ ___',

    // Sao Tome e Principe
    ['+239']: '+239 ___ ___ __ __ ___ ___',

    // Saudi Arabia
    ['+966']: '+966 ___ ___ __ __ ___ ___',

    // Senegal
    ['+221']: '+221 ___ ___ __ __ ___ ___',

    // Seychelles
    ['+248']: '+248 ___ ___ __ __ ___ ___',

    // Sierra Leone
    ['+232']: '+232 ___ ___ __ __ ___ ___',

    // Singapore
    ['+65']: '+65 ___ ___ __ __ ___ ___',

    // Slovakia
    ['+421']: '+421 ___ ___ __ __ ___ ___',

    // Slovenia
    ['+386']: '+386 ___ ___ __ __ ___ ___',

    // Solomon Islands
    ['+677']: '+677 ___ ___ __ __ ___ ___',

    // Somalia
    ['+252']: '+252 ___ ___ __ __ ___ ___',

    // South Africa
    ['+27']: '+27 ___ ___ __ __ ___ ___',

    // South Korea
    ['+82']: '+82 ___ ___ __ __ ___ ___',

    // South Yemen
    ['+969']: '+969 ___ ___ __ __ ___ ___',

    // Spain
    ['+34']: '+34 ___ ___ __ __ ___ ___',

    // Sri Lanka
    ['+94']: '+94 ___ ___ __ __ ___ ___',

    // St.Kitts and Nevis
    ['+1-869']: '+1-869 ___ ___ __ __ ___ ___',

    // St.Lucia
    ['+1-758']: '+1-758 ___ ___ __ __ ___ ___',

    // St.Vincent and the Grenadines
    ['+1-784']: '+1-784 ___ ___ __ __ ___ ___',

    // Sudan
    ['+249']: '+249 ___ ___ __ __ ___ ___',

    // Suriname
    ['+597']: '+597 ___ ___ __ __ ___ ___',

    // Svalbard and Jan Mayen Islands
    ['+47SJ']: '+47 ___ ___ __ __ ___ ___',

    // Swaziland
    ['+268']: '+268 ___ ___ __ __ ___ ___',

    // Sweden
    ['+46']: '+46 ___ ___ __ __ ___ ___',

    // Switzerland
    ['+41S']: '+41 ___ ___ __ __ ___ ___',

    // Syria
    ['+963']: '+963 ___ ___ __ __ ___ ___',

    // Ta(d)jikistan
    ['+992']: '+992 ___ ___ __ __ ___ ___',

    // Taiwan
    ['+886']: '+886 ___ ___ __ __ ___ ___',

    // Tanzania
    ['+255']: '+255 ___ ___ __ __ ___ ___',

    // Thailand
    ['+66']: '+66 ___ ___ __ __ ___ ___',

    // Togolese Republic
    ['+228']: '+228 ___ ___ __ __ ___ ___',

    // Tokelau
    ['+690']: '+690 ___ ___ __ __ ___ ___',

    // Tonga
    ['+676']: '+676 ___ ___ __ __ ___ ___',

    // Trinidad and Tobago
    ['+1-868']: '+1-868 ___ ___ __ __ ___ ___',

    // Tunisia
    ['+21T']: '+21 ___ ___ __ __ ___ ___',

    // Turkey
    ['+90']: '+90 ___ ___ __ __ ___ ___',

    // Turkmenistan
    ['+993']: '+993 ___ ___ __ __ ___ ___',

    // Turks & Caicos Islands
    ['+1-649']: '+1-649 ___ ___ __ __ ___ ___',

    // Tuvalu
    ['+688']: '+688 ___ ___ __ __ ___ ___',

    // US Virgin Islands
    ['+1-340']: '+1-340 ___ ___ __ __ ___ ___',

    // Uganda
    ['+256']: '+256 ___ ___ __ __ ___ ___',

    // Ukraine
    ['+380']: '+380 ___ ___ __ __ ___ ___',

    // United Arab Emirates
    ['+971']: '+971 ___ ___ __ __ ___ ___',

    // United Kingdom
    ['+44']: '+44 ___ ___ __ __ ___ ___',

    // Uruguay
    ['+598']: '+598 ___ ___ __ __ ___ ___',

    // USA
    ['+1']: '+1 ___ ___ __ __ ___ ___',

    // Uzbekistan
    ['+998']: '+998 ___ ___ __ __ ___ ___',

    // Vanuatu
    ['+678']: '+678 ___ ___ __ __ ___ ___',

    // Vatican City
    ['+39VC']: '+39 ___ ___ __ __ ___ ___',

    // Venezuela
    ['+58']: '+58 ___ ___ __ __ ___ ___',

    // Vietnam
    ['+84']: '+84 ___ ___ __ __ ___ ___',

    // Wallis and Futuna Islands
    ['+681']: '+681 ___ ___ __ __ ___ ___',

    // Western Sahara
    ['+21WS']: '+21 ___ ___ __ __ ___ ___',

    // Western Samoa
    ['+685']: '+685 ___ ___ __ __ ___ ___',

    // Yugoslavia
    ['+381']: '+381 ___ ___ __ __ ___ ___',

    // Zaire
    ['+243']: '+243 ___ ___ __ __ ___ ___',

    // Zambia
    ['+260']: '+260 ___ ___ __ __ ___ ___',

    // Zimbabwe
    ['+263']: '+263 ___ ___ __ __ ___ ___'
};

export const PHONE_OPTIONS = [
    { value: 'ru', label: '+7' },
    { value: 'bel', label: '+375' }
];
