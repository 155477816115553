import styled, { css } from 'styled-components';
import Collapse from '@kunukn/react-collapse';
import { font, color, offset, mediaBreakpointUp } from '@/style/mixins';
import { Container as Documents, DocumentsWrapper } from '@/components/blocks/Documents/Documents.styled';
import variables from '@/style/variables';

interface SpoilerHeadBtnProps {
    isOpen?: boolean;
    arrowIcon?: boolean;
    plusIcon?: boolean;
    menuIcon?: boolean;
}

export const Container = styled.div`
    position: relative;

    ${Documents} {
        margin: 0;
    }

    ${DocumentsWrapper} {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;
export const SpoilerHead = styled.div<{ menuIcon?: boolean }>`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: ${variables.corners.smallUi};
    justify-content: ${({ menuIcon }) => (menuIcon ? 'flex-start' : 'space-between')};
    transition: background-color 0.3s ease-in-out;

    ${({ menuIcon }) => menuIcon && offset('xs', 'gap')};

    -webkit-tap-highlight-color: transparent;

    svg {
        width: 12px;
        height: 12px;
        stroke: ${color('white', 0.6)};
    }

    &._open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    ${mediaBreakpointUp('md')} {
        width: 24px;
        height: 24px;
    }
    svg {
        height: inherit !important;
        width: inherit !important;
        position: absolute;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
`;

export const SpoilerHeadBtn = styled.div<SpoilerHeadBtnProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ menuIcon }) =>
        menuIcon &&
        css`
            .icon_menu {
                fill: ${color('blue')};
            }

            .icon_closed {
                fill: ${color('DeepBlue/900')};
            }
        `}

    ${({ plusIcon, isOpen }) =>
        plusIcon &&
        css`
            position: relative;
            margin-left: auto;
            width: 13px;
            height: 13px;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 2px;
                background: currentColor;
                transition: transform 0.3s ease-in-out;
                top: calc(50% - 1px);
            }

            &:before {
                transform: ${isOpen ? 'rotate(90deg) scale(0)' : 'rotate(0) scale(1)'};
            }

            &:after {
                transform: ${isOpen ? 'rotate(180deg)' : 'rotate(90deg)'};
            }

            svg {
                transform: ${isOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
            }
        `}

    ${({ arrowIcon, isOpen }) =>
        arrowIcon &&
        css`
            svg {
                display: block;
                width: 14px;
                height: 14px;
                stroke: currentcolor;
                transition: transform 0.3s ease-in-out;
            }

            &:before {
                transform: ${isOpen ? 'rotate(90deg) scale(0)' : 'rotate(0) scale(1)'};
            }

            &:after {
                transform: ${isOpen ? 'rotate(180deg)' : 'rotate(90deg)'};
            }

            svg {
                transform: ${isOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
            }
        `}
`;
export const SpoilerHeadText = styled.div`
    ${offset('xs', 'margin-right')}
    ${font('text2-b')}
`;

export const SpoilerBody = styled(Collapse)`
    transition: height 0.3s ease-in-out;
`;
