import { MouseEvent, useEffect, useRef } from 'react';
import { Container, LoadingBtn, LoadingBtnImage } from './WebimLoadingBtn.styled';
import { useRouter } from 'next/router';
import { useWebimButtonOffset } from '@/hooks/useWebimButtonOffset';

// webim button generation date: 2023-01-16 version: 10.4.136

const MAX_COUNT = 40;
const CHAT_IMAGE_SRC = '/images/chat_frame.png';
let loadingBtnIsHidden = false;

export const WebimLoadingBtn = () => {
    const loadingBtnRef = useRef<HTMLDivElement | null>(null);
    const { asPath } = useRouter();
    const { setWebimButtonsOffsets } = useWebimButtonOffset();

    const clickHandler = (evt: MouseEvent<HTMLDivElement>) => {
        const delay = 350;
        let count = 0;

        evt.currentTarget.classList.add('hidesBtn');
        loadingBtnIsHidden = true;

        (window as any).webim = { accountName: 'tektorgru', domain: 'tektorgru.webim.ru', location: 'departments' };

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://tektorgru.webim.ru/js/button.js';
        document.head.appendChild(script);

        const btnClick = () => {
            const webim_button = document.querySelector('a.webim_button.webim-button-slider') as HTMLElement;

            if (webim_button) {
                webim_button.style.cssText = '';
                webim_button.style.opacity = '1';
                setWebimButtonsOffsets();

                const img = webim_button.querySelector('img') as HTMLImageElement;
                if (img) {
                    img.style.cssText = '';
                    img.src = CHAT_IMAGE_SRC;
                }
                webim_button.click();
                clearInterval(interval);
            }

            // webim_button && (webim_button.style.transition = "0s");
            // webim_button && (webim_button.style.opacity = "0");

            count++;
            if (count >= MAX_COUNT) clearInterval(interval);
        };

        const interval = setInterval(btnClick, delay);
    };
    useEffect(() => {
        requestAnimationFrame(() => {
            if (loadingBtnRef.current) {
                loadingBtnRef.current.style.opacity = '1';
                loadingBtnRef.current.style.visibility = 'visible';
            }
        });
    }, []);

    useEffect(() => {
        if (loadingBtnIsHidden && loadingBtnRef.current) {
            loadingBtnRef.current.classList.add('hidesBtn');
        }
    }, [asPath, loadingBtnIsHidden]);

    return (
        <Container ref={loadingBtnRef} onClick={clickHandler} className={'webim-button-loading'}>
            {!loadingBtnIsHidden ? (
                <LoadingBtn>
                    <LoadingBtnImage src={CHAT_IMAGE_SRC} layout="fill" priority />
                </LoadingBtn>
            ) : null}
        </Container>
    );
};
