import { Container, Controls, Description, Title, TitleText } from './Alert.styled';
import CircleCheck from '@/icons/circleCheck.svg';
import CircleWarning from '@/icons/circleWarning.svg';
import Button from '@/components/blocks/Button/Button';
import { BaseOffsetsUI, IAlert } from '@/interfaces';

export interface AlertProps extends IAlert, BaseOffsetsUI {
    type?: 'success' | 'error';
    additionalBtnText?: string;
    isAgreementLoading?: boolean;
    isAdditionalLoading?: boolean;
    hasSideOffsets?: boolean;
    additionalBtnHandler?: (e: React.SyntheticEvent) => void;
    agreementHandler?: (e: React.SyntheticEvent) => void;
}

const Alert: React.FC<AlertProps> = ({
    type = 'success',
    title,
    description,
    additionalBtnText,
    additionalBtnHandler,
    agreementHandler,
    isAgreementLoading,
    isAdditionalLoading,
    topOffset,
    bottomOffset,
    hasSideOffsets
}) => {
    return (
        <Container topOffset={topOffset} bottomOffset={bottomOffset} hasSideOffsets={hasSideOffsets}>
            {title && (
                <Title type={type}>
                    {type === 'success' ? <CircleCheck /> : <CircleWarning />}
                    <TitleText dangerouslySetInnerHTML={{ __html: title }} />
                </Title>
            )}

            {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}

            <Controls>
                <Button
                    haveBg
                    bgColor="Bg-Dark"
                    textColor="text-oncolor-primary"
                    onClick={agreementHandler}
                    disabled={isAgreementLoading}
                    isLoading={isAgreementLoading}
                >
                    OK
                </Button>
                {additionalBtnHandler && (
                    <Button
                        haveBg
                        bgColor="gray6"
                        textColor="black"
                        isLoading={isAdditionalLoading}
                        onClick={additionalBtnHandler}
                        disabled={isAdditionalLoading}
                    >
                        {additionalBtnText}
                    </Button>
                )}
            </Controls>
        </Container>
    );
};

export default Alert;
