export type GuaranteeCalculatorType = {
    cost: number;
    loanPeriod: number;
    purchase: string;
    guarantee: string;
    fillingDate?: string | null;
    guaranteeResultSum: number | null;
    annualPercentage: number | null;
};

export const GARANTEE_CALCULATOR = 'guaranteeCalculator';
export type GARANTEE_CALCULATOR = typeof GARANTEE_CALCULATOR;
