import { color, hover } from '@/style/mixins';
import variables from '@/style/variables';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isVisible: boolean }>`
    position: fixed;
    bottom: 106px;
    right: 32px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    height: 64px;
    width: 64px;
    display: block;
    z-index: 2;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    ${({ isVisible }) =>
        isVisible &&
        css`
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        `};
`;

export const ScrollButton = styled.button`
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    background-color: ${color('Content-02')};
    border-radius: ${variables.corners.smallCard};
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    ${hover(`
        background-color: ${color('Content-02-hover')};
    `)}

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 15px;
        height: 0;
        border-top: 2px solid ${color('ui-icon-default')};
        top: 60%;
    }

    &::before {
        transform-origin: left center;
        transform: translate(-50%, -50%) rotate(-45deg);
        left: 46%;
    }

    &::after {
        transform-origin: right center;
        transform: translate(50%, -50%) rotate(45deg);
        right: 46%;
    }
`;
