import styled, { css } from 'styled-components';
import { color, font, hover, mediaBreakpointUp, offset } from '@/style/mixins';
import InputSelect from '@/components/ui/InputSelect/InputSelect';
import variables from '@/style/variables';

export const INPUT_PADDINGS = css<{ withValue?: boolean }>`
    padding: ${({ withValue }) => (withValue ? '24px 30px 7px 16px' : '15px 30px 15px 16px')};

    ${mediaBreakpointUp('md')} {
        padding: ${({ withValue }) => (withValue ? '24px 40px 7px 20px' : '15px 40px 15px 20px')};
    }
`;

export const INPUT_STYLE = css`
    ${font('text2-sb')};
    width: 100%;

    background-color: ${color('ui-bg')};
    outline: none;
    border: none;
    border-radius: ${variables.corners.smallUi};
    transition: background-color 0.3s ease-in-out;

    ${INPUT_PADDINGS};

    &:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px ${color('ui-bg')} !important;

        &:hover {
            -webkit-box-shadow: inset 0 0 0 50px ${color('ui-bg-hover')} !important;
        }
    }

    &:not(.tel-input):focus {
        padding: 24px 20px 7px;

        &::placeholder {
            color: ${color('ui-label-default', 0)};
        }
    }

    &::placeholder {
        color: ${color('ui-label-default', 1)};
        opacity: 1;
    }
`;

export const PlaceholderTop = styled.span<{ withValue?: boolean }>`
    position: absolute;
    top: 7px;
    left: 16px;
    ${font('text4')};
    color: ${color('ui-label-default')};
    white-space: nowrap;
    max-width: calc(100% - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    line-clamp: 1;

    ${mediaBreakpointUp('md')} {
        left: 20px;
        max-width: calc(100% - 40px);
    }

    ${({ withValue }) =>
        !withValue &&
        css`
            display: none;
        `};
`;

export const ErrorText = styled.span`
    ${font('text4')};
    margin-top: 4px;
    display: block;
`;

export const Container = styled.label<{ withValue?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;

    &:not(.input-calendar-form) {
        input,
        textarea {
            ${INPUT_STYLE};

            &:focus {
                & ~ ${PlaceholderTop} {
                    display: initial;
                }
            }
        }
    }

    ${mediaBreakpointUp('xl')} {
        ${hover(`
                & > input,
                textarea {
                    background-color: ${color('ui-bg-hover')};
                }
        `)};
    }

    &._error {
        ${ErrorText} {
            color: ${color('Error')};
        }
    }

    input {
        height: 48px;

        ${mediaBreakpointUp('md')} {
            height: 52px;
        }
    }

    textarea {
        resize: none;
        height: 104px;
    }

    &.textarea-small {
        textarea {
            height: 60px;
        }
    }
`;

export const PhoneSelect = styled(InputSelect)`
    max-width: 103px;
    flex-shrink: 0;
    display: flex;

    .InputSelect__indicators,
    .InputSelect__value-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .InputSelect__control {
        border-radius: ${variables.corners.smallUi};
    }

    .InputSelect__value-container {
        padding-left: 20px;
        padding-right: 0;
    }

    .InputSelect__indicator {
        padding-right: 20px;
        padding-left: 8px;
    }

    .InputSelect__single-value {
        position: static;
        max-width: none;
        transform: none;
        margin: 0;

        & + input {
            position: absolute;
        }
    }
`;

export const PhonePlaceholder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    ${font('text2-sb')};
    color: ${color('ui-label-default')};
    background-color: ${color('ui-bg')};
    line-height: 1.1;
    border-radius: ${variables.corners.smallUi};
    opacity: 0;
    visibility: hidden;
    z-index: -2;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, z-index 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

    ${hover(`
        background-color: ${color('ui-bg-hover')};
    `)};

    ._phShow & {
        opacity: 1;
        visibility: visible;
        z-index: 0;
    }
    ._error & {
        display: none;
    }

    ${INPUT_PADDINGS};
`;
export const PhoneInputs = styled.div`
    display: flex;
    gap: 4px;
    transition: opacity 0.3s ease-in-out;

    ._phShow & {
        opacity: 0;

        ._error & {
            opacity: 1;
        }
    }

    & > * + input {
        border: none;
    }

    & > input {
        ${mediaBreakpointUp('xl')} {
            ${hover(`
                background-color: ${color('ui-bg-hover')};
            `)};
        }
    }
`;

export const PhoneWrapper = styled.div`
    position: relative;
`;

export const Descr = styled.p`
    margin-top: 12px;
    color: ${color('text-tertiary')};
    ${font('text4')};
    ${offset('xs', 'margin-bottom')}
`;
