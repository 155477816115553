import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Bg, BtnSubmit, SuccessMessage } from '@/components/ui/BaseFormCommon/BaseFormCommon.styled';
import { BaseFormCommonI, IAlert, RequestStatusForm } from '@/interfaces';
import { configRules } from '@/helpers/configRegExp';
import InputForm, { FormInputNames } from '@/components/ui/InputForm/InputForm';
import { SubmitHandler, useForm } from 'react-hook-form/dist/index.ie11';
import useTranslation from '@/hooks/useTranslation';
import { useRouter } from 'next/router';
import CircularAnimation from '@/components/blocks/CircularAnimation/CircularAnimation';
import { submitMetriksForm } from '@/metriks/metriksSample';
import { postSubscribeForm } from '@/lib/api';
import Alert from '@/components/common/Alert/Alert';
import { useFormAlert } from '@/hooks/forms/useFormAlert';
import { useURLFormData } from '@/hooks/forms/useURLFormData';
import { ContainerSubscribeForm, WrapperBtnText, WrapperText } from './SubscribeForm.styled';
import Link from 'next/link';

interface SubscribeFormProps extends BaseFormCommonI {
    confirmMessage?: { text: string; link: { href: string; text: string } };
    fieldEmailFormData?: string;
}

interface SubmitData {
    email: string;
}

const SubscribeForm: React.FC<SubscribeFormProps> = ({
    formId,
    fieldEmailFormData,
    title,
    text,
    confirmMessage,
    picture,
    topOffset,
    bottomOffset,
    outsideTOffset,
    outsideBOffset,
    successAlert,
    errorAlert
}) => {
    const { t } = useTranslation('common');
    const [requestStatus, setRequestStatus] = useState<RequestStatusForm>('idle');
    const timer = useRef<NodeJS.Timeout | null>(null);
    const { pathname } = useRouter();

    const { utm_source, utm_medium, utm_campaign, utm_content, utm_term, pagePath } = useURLFormData();

    const { submitStatus, setSubmitStatus, dataRef, closeAlert } = useFormAlert();
    const formSuccessAlert: IAlert = successAlert ?? t('formSuccessAlert5', { returnObjects: true });
    const formErrorAlert: IAlert = errorAlert ?? t('formErrorAlert', { returnObjects: true });

    const {
        register,
        reset,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const field = watch('email', '');

    const clearForm = useCallback(() => {
        reset();
        setRequestStatus('idle');
    }, []);

    const onSubmit: SubmitHandler<SubmitData> = async ({ email }) => {
        dataRef.current = { email };
        setRequestStatus('loading');

        const formData = new FormData();

        formData.append(fieldEmailFormData || 'email', email);

        formData.append('additional_fields[utm_source]', utm_source);
        formData.append('additional_fields[utm_medium]', utm_medium);
        formData.append('additional_fields[utm_campaign]', utm_campaign);
        formData.append('additional_fields[utm_content]', utm_content);
        formData.append('additional_fields[utm_term]', utm_term);
        formData.append('additional_fields[pathname]', pagePath);

        try {
            const response = await postSubscribeForm(formData);
            if (response.status === 201) {
                setRequestStatus('succeeded');
                setSubmitStatus('success');
                try {
                    submitMetriksForm(pathname, formId);
                } catch (err) {
                    console.log('Metrik_Err: ', err);
                }
            } else {
                setRequestStatus('failed');
                setSubmitStatus('error');
            }
        } catch (e) {
            console.error(e);
            setRequestStatus('failed');
            setSubmitStatus('error');
        } finally {
            timer.current = setTimeout(clearForm, 3500);
        }
    };

    useEffect(() => {
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, []);

    return (
        <ContainerSubscribeForm
            topOffset={topOffset}
            bottomOffset={bottomOffset}
            outsideTOffset={outsideTOffset}
            outsideBOffset={outsideBOffset}
        >
            {picture && <Bg {...picture} />}

            {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
            {text && <p dangerouslySetInnerHTML={{ __html: text }} />}

            {submitStatus === 'success' && <Alert {...formSuccessAlert} type="success" topOffset="xl" agreementHandler={closeAlert} />}

            {submitStatus === 'error' && (
                <Alert
                    {...formErrorAlert}
                    type="error"
                    topOffset="xl"
                    additionalBtnText={t('resend')}
                    additionalBtnHandler={() => onSubmit(dataRef.current)}
                    agreementHandler={closeAlert}
                    isAdditionalLoading={requestStatus === 'loading'}
                />
            )}

            {!submitStatus && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputForm
                            placeholder={'E-mail'}
                            type={'email'}
                            name={'email'}
                            inputName={FormInputNames.email}
                            register={register(configRules.mailRules(t))}
                            required={true}
                            errors={errors.email}
                        />

                        <WrapperBtnText>
                            <BtnSubmit
                                type={'submit'}
                                className={requestStatus === 'loading' ? '_loading' : ''}
                                disabled={errors.email || field.length <= 2 || requestStatus !== 'idle'}
                            >
                                <span>{t('sendSubscribe')}</span>
                                {requestStatus === 'loading' && <CircularAnimation loadingAnimation />}
                            </BtnSubmit>

                            {confirmMessage && (
                                <WrapperText>
                                    <p dangerouslySetInnerHTML={{ __html: confirmMessage.text }} className="sb-t4" />
                                    <Link href={confirmMessage.link.href} target={'_blank'}>
                                        {confirmMessage.link.text}
                                    </Link>
                                </WrapperText>
                            )}
                        </WrapperBtnText>
                    </form>

                    {(requestStatus === 'succeeded' || requestStatus === 'failed') && (
                        <SuccessMessage>
                            {requestStatus === 'succeeded' && t('successMessageSubscribe')}
                            {requestStatus === 'failed' && t('failedMessage')}
                        </SuccessMessage>
                    )}
                </>
            )}
        </ContainerSubscribeForm>
    );
};

export default SubscribeForm;
