import { _223FZ } from './pathes/223-fz';
import { _44FZ } from './pathes/44-fz';
import { Clients } from './pathes/clients';
import { Education } from './pathes/education';
import { InterRao } from './pathes/inter-rao';
import { Market } from './pathes/market';
import { News } from './pathes/news';
import { Partners } from './pathes/partners';
import { Rosneft } from './pathes/rosneft';
import { Sale } from './pathes/sale';
import { Services } from './pathes/services';
import { Subscription } from './pathes/subscription';
import { Tenders } from './pathes/tenders';
import { Finance } from './pathes/fin-services';
import { Index } from './pathes/index';
import { TradeSections } from './pathes/trade-sections';

/** */
const pagePathes = {
    ..._44FZ,
    ..._223FZ,
    ...Clients,
    ...Education,
    ...InterRao,
    ...Market,
    ...News,
    ...Partners,
    ...Rosneft,
    ...Sale,
    ...Services,
    ...Tenders,
    ...Finance,
    ...Subscription,
    ...Index,
    ...TradeSections
};

export default pagePathes;
