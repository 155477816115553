import { css, FlattenSimpleInterpolation } from 'styled-components';
import variables from '@/style/variables';

const rem: (size: number, rootFontSize?: number) => string = (size, rootFontSize = 16) => {
    return `${size / rootFontSize}rem`;
};
export const remFluid: (
    property: string,
    mobile375: number,
    tablet768: number,
    desktop1280: number,
    desktop1440: number,
    desktop1920: number,
    rootFontSize?: number
) => FlattenSimpleInterpolation = (property, mobile375, tablet768, desktop1280, desktop1440, desktop1920, rootFontSize = 16) => {
    const mobile375Rem = rem(mobile375);
    const tablet768Rem = rem(tablet768);
    const desktop1280Rem = rem(desktop1280);
    const desktop1440Rem = rem(desktop1440);
    const desktop1920Rem = rem(desktop1920);

    return css`
        //mobile
        ${property}: ${rem(mobile375 * 0.8)};

        @media (min-width: 370px) {
            ${property}: calc(${mobile375Rem} + (${tablet768 / rootFontSize} - ${mobile375 / rootFontSize}) *
            ((100vw - ${rem(370)}) / (767 - 370)));
        }

        // tablet
        @media (min-width: 767px) {
            ${property}: calc(${tablet768Rem} + (${desktop1280 / rootFontSize} - ${tablet768 / rootFontSize}) *
            ((100vw - ${rem(767)}) / (1280 - 767)));

            @media (max-height: 500px) {
                ${property}: calc(${mobile375Rem} + (${(desktop1920 / rootFontSize) * 0.7} - ${mobile375 / rootFontSize}) *
                ((100vw - ${rem(767)}) / (1280 - 767)));
            }
        }

        //min 1024
        @media (min-width: 1024px) {
            ${property}: calc(${desktop1280Rem} + (${desktop1440 / rootFontSize} - ${desktop1280 / rootFontSize}) *
            ((100vw - ${rem(1280)}) / (1440 - 1280)));

            @media (max-height: 500px) {
                ${property}: calc(${mobile375Rem} + (${(desktop1920 / rootFontSize) * 0.7} - ${mobile375 / rootFontSize}) *
          ((100vw - ${rem(767)}) / (1920 - 767)));
            }
        }

        //min 1440
        @media (min-width: 1440px) {
            ${property}: calc(${desktop1440Rem} + (${desktop1920 / rootFontSize} - ${desktop1280 / rootFontSize}) *
            ((100vw - ${rem(1440)}) / (1920 - 1440)));
        }

        // больше 1920
        @media (min-width: 1920px) {
            ${property}: ${desktop1920Rem};

            @media (max-height: 500px) {
                ${property}: calc(${mobile375Rem} + (${(desktop1920 / rootFontSize) * 0.7} - ${mobile375 / rootFontSize}) *
                ((100vw - ${rem(767)}) / (1920 - 767)));
            }
        }

        @media (min-width: 2000px) {
            ${property}: ${(desktop1920 / 1920) * 100}vw;
        }
    `;
};

const remAdaptiveFont = (
    mobile375: number,
    tablet768: number,
    desktop1280: number,
    desktop1440: number,
    desktop1920: number
): FlattenSimpleInterpolation => {
    return remFluid('font-size', mobile375, tablet768, desktop1280, desktop1440, desktop1920, 16);
};

const adaptiveHardFont = (desktop1280: number, tablet768: number, mobile375: number): FlattenSimpleInterpolation => {
    return css`
        font-size: ${`${mobile375}px`};

        @media (min-width: 768px) {
            font-size: ${`${tablet768}px`};
        }

        @media (min-width: 1280px) {
            font-size: ${`${desktop1280}px`};
        }
    `;
};

const typography = {
    h1: css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 800;
        line-height: 120%;
        letter-spacing: -0.02em;
        ${adaptiveHardFont(40, 36, 28)};
    `,
    h2: css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 800;
        line-height: 105%;
        letter-spacing: -0.02em;
        ${adaptiveHardFont(30, 28, 22)};
    `,
    h3: css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 800;
        line-height: 115%;
        letter-spacing: -0.02em;
        ${adaptiveHardFont(23, 20, 18)};
    `,
    't1-body-exb': css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 800;
        line-height: 140%;
        ${adaptiveHardFont(18, 18, 14)};
    `,
    't1-body-sb': css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 600;
        line-height: 140%;
        ${adaptiveHardFont(18, 18, 14)};
    `,
    'text2-b': css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 700;
        line-height: 130%;
        ${adaptiveHardFont(16, 16, 14)};
    `,
    'text2-sb': css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 600;
        line-height: 130%;
        ${adaptiveHardFont(16, 16, 14)};
    `,
    'text3-sb': css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 600;
        line-height: 1;
        ${adaptiveHardFont(14, 14, 12)};
    `,
    'text3-r': css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 400;
        line-height: 1;
        ${adaptiveHardFont(14, 14, 12)};
    `,
    text4: css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 600;
        line-height: 140%;
        ${adaptiveHardFont(12, 12, 12)};
    `,
    menu: css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 700;
        line-height: 1;
        ${adaptiveHardFont(12, 12, 12)};
    `,
    ui: css`
        font-family: ${variables.fonts.NunitoSans};
        font-weight: 700;
        line-height: 1;
        ${adaptiveHardFont(16, 16, 14)};
    `,

    'h4-l': css`
        ${remAdaptiveFont(24, 27, 24, 27, 36)};
        line-height: 1.1;
        font-family: ${variables.fonts.NunitoSansLight};
    `,
    'h4-b': css`
        ${remAdaptiveFont(24, 27, 24, 27, 36)};
        line-height: 1.1;
        font-family: ${variables.fonts.NunitoSansSemiBold};
    `,
    'h5-l': css`
        ${remAdaptiveFont(17, 17, 18, 21, 28)};
        line-height: 1.2;
        font-family: ${variables.fonts.NunitoSansLight};
    `,
    'h5-b': css`
        ${remAdaptiveFont(17, 17, 18, 21, 28)};
        line-height: 1.2;
        font-family: ${variables.fonts.NunitoSansSemiBold};
    `,
    'text1-l': css`
        ${remAdaptiveFont(15, 15, 14, 16, 21)};
        line-height: 1.3;
        font-family: ${variables.fonts.NunitoSansLight};
    `,
    'text1-b': css`
        ${remAdaptiveFont(15, 15, 14, 16, 21)};
        line-height: 1.3;
        font-family: ${variables.fonts.NunitoSansSemiBold};
    `,
    'text2-l': css`
        ${remAdaptiveFont(14, 14, 13, 14, 17)};
        line-height: 1.4;
        font-family: ${variables.fonts.NunitoSansLight};
    `,
    'text3-b': css`
        ${remAdaptiveFont(13, 13, 12, 13, 15)};
        line-height: 1.15;
        font-family: ${variables.fonts.NunitoSansSemiBold};
        letter-spacing: 0.2px;
    `,
    'text3-l': css`
        ${remAdaptiveFont(13, 13, 12, 13, 15)};
        line-height: 1.15;
        font-family: ${variables.fonts.NunitoSansLight};
        letter-spacing: 0.2px;
    `,
    button: css`
        ${remAdaptiveFont(15, 15, 14, 16, 21)};
        line-height: 1;
        font-family: ${variables.fonts.NunitoSansSemiBold};
    `,
    num: css`
        ${remAdaptiveFont(42, 48, 57, 64, 86)};
        line-height: 1;
        font-family: ${variables.fonts.NunitoSansSemiBold};
    `,
    num2: css`
        ${remAdaptiveFont(26, 32, 19, 32, 42)};
        line-height: 1;
        font-family: ${variables.fonts.NunitoSansSemiBold};
    `
};

export type Typography = keyof typeof typography;

export default typography;
