import styled from 'styled-components';
import { allBreakpointValue, color, font, mediaBreakpointDown, mediaBreakpointUp } from '@/style/mixins';
import { Container as LangContainer } from '@/components/common/Header/HeaderLang/HeaderLang.styled';
import { Container as SearchContainer } from '@/componentsNew/blocks/HeaderSearch/HeaderSearch.styled';

type HeaderContainerT = {
    overBg?: boolean;
    lineColor?: number;
    layOutOffset?: number;
};
export const Container = styled.header<HeaderContainerT>`
    position: absolute;
    top: ${({ layOutOffset }) => (layOutOffset ? `calc(${layOutOffset}px + 16px)` : '16px')};
    left: 20px;
    right: 20px;
    z-index: 4;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    color: ${color('text-primary')};
    background-color: ${color('Content-01')};
    border-radius: 14px;
    margin: 0 auto;
    padding: 4px 4px 4px 20px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        top: ${({ layOutOffset }) => (layOutOffset ? `calc(${layOutOffset}px + 20px)` : '20px')};
        padding-left: 12px;
    }

    ${mediaBreakpointUp('xl')} {
        left: 0;
        right: 0;
        width: 1200px;
        padding-left: 28px;
    }

    &._dark,
    &._openSandwich {
        color: ${color('text-oncolor-primary')};
        background-color: ${color('DeepBlue/800')};
    }

    ${LangContainer} {
        ${mediaBreakpointDown('md')} {
            display: none;
        }
    }

    ${SearchContainer} {
        ${mediaBreakpointDown('md')} {
            margin-top: 16px;
        }
    }
`;

export const HeaderLogo = styled.a`
    width: 96px;
    height: 22px;
    flex-shrink: 0;
    align-self: center;
    margin-right: auto;

    ${mediaBreakpointUp('md')} {
        width: 116px;
        height: 28px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-right: 28px;
    }

    svg {
        fill: currentColor;
        transition: color 0.3s ease-in-out;
        width: auto;
        height: 100%;

        .logo-r_t {
            stroke: ${color('brandPrimary')};
            fill: ${color('brandPrimary')};
        }
    }
`;

export const HeaderLogin = styled.div`
    position: relative;
    cursor: pointer;
    color: currentColor;
    background-color: ${color('Bg-Light')};
    border-radius: 10px;
    margin-left: 4px;
    padding: 15px 12px;
    ${font('ui')}
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        padding: 20px 16px;
    }

    ${mediaBreakpointUp('xl')} {
        padding: 16px;
    }

    ._dark &,
    ._openSandwich & {
        background-color: ${color('DeepBlue/500')};
    }
`;

export const HeaderMenu = styled.nav`
    display: none;
    align-items: center;
    padding: 0;
    color: currentColor;
    ${font('menu')};

    ${mediaBreakpointUp('xl')} {
        display: flex;
        width: 100%;
    }

    & > li {
        list-style: none;

        &:not(:last-child) {
            ${allBreakpointValue('margin-right', 24, 24, 24)};
        }

        span {
            position: relative;
            text-transform: uppercase;
            transition: color 0.3s ease-in-out;

            &.current:before {
                content: '';
                width: 100%;
                height: 1px;
                background-color: currentColor;
                position: absolute;
                bottom: -2px;
            }

            a {
                transition: opacity 0.3s ease-in-out;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
`;

export const HeaderSandwichBtn = styled.button`
    cursor: pointer;
    position: relative;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4px;
    color: currentColor;
    background-color: ${color('Bg-Light')};
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;

    ._dark &,
    ._openSandwich & {
        background-color: ${color('DeepBlue/500')};
    }

    &:before,
    &:after {
        content: '';
        width: calc(100% - 14px * 2);
        height: 2px;
        margin: 0 auto;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        right: 0;
        background-color: currentColor;
        transition: transform 0.3s ease-in-out;
    }

    &:before {
        transform: translateY(-4px);

        ._openSandwich & {
            transform: translateY(0) rotate(45deg);
        }
    }

    &:after {
        transform: translateY(4px);

        ._openSandwich & {
            transform: translateY(0) rotate(-45deg);
        }
    }
`;

export const HeaderInnerWrapper = styled.div`
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    ._openSandwich & {
        opacity: 1;
        visibility: visible;
    }

    ${mediaBreakpointUp('md')} {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        flex-direction: row;
        justify-content: flex-start;
        opacity: 1;
        visibility: visible;
        width: 100%;
        flex: 1;
        padding-right: 60px;
    }

    ${mediaBreakpointUp('xl')} {
        padding-right: 48px;
    }
`;
