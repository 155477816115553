import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GARANTEE_CALCULATOR, GuaranteeCalculatorType } from './type';
import { GUARANTEE_CALCULATOR_DEFAULT_VALUES } from '@/lib/variables';

export const initialState: GuaranteeCalculatorType = {
    ...GUARANTEE_CALCULATOR_DEFAULT_VALUES
};

const guaranteeCalculatorSlice = createSlice({
    name: GARANTEE_CALCULATOR,
    initialState,
    reducers: {
        setGuaranteeCalculatorValues: (state: GuaranteeCalculatorType, { payload }: PayloadAction<GuaranteeCalculatorType>) => {
            return { ...state, ...payload };
        },
        resetGuaranteeCalculatorValues: (
            state: GuaranteeCalculatorType,
            { payload }: PayloadAction<undefined | Pick<GuaranteeCalculatorType, 'fillingDate'>>
        ) => {
            return { ...state, ...initialState, ...{ payload } };
        }
    }
});

export const { setGuaranteeCalculatorValues, resetGuaranteeCalculatorValues } = guaranteeCalculatorSlice.actions;

export default guaranteeCalculatorSlice.reducer;
