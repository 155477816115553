import styled from 'styled-components';
import { color, mediaBreakpointUp } from '@/style/mixins';

export const Container = styled.div`
    height: 48px;
    width: 40px;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: ${color('light-blue-background')};
    border-radius: 10px;
    display: flex;
    transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        height: 100%;
    }

    &._show {
        width: 100%;
    }

    ._dark &,
    ._openSandwich & {
        background-color: ${color('DeepBlue/500')};
    }
`;

export const HeaderSearchIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 12px;
    cursor: pointer;

    svg {
        fill: currentColor;
        transition: fill 0.3s ease-in-out;
    }
`;

export const HeaderSearchInputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 0;

    input {
        width: 100%;
        border: 0;
        outline: 0;
        background-color: transparent;
        color: currentColor;

        &::-ms-clear {
            display: none;
            height: 0;
            width: 0;
        }
    }

    ._show & {
        flex-grow: 1;
    }
`;

export const HeaderSearchClear = styled.div`
    width: calc(14px + 12px * 2);
    height: calc(14px + 16px * 2);
    position: relative;
    padding: 16px 12px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;

    ${mediaBreakpointUp('xl')} {
        margin-left: 22px;
    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: calc(100% - 32px);
        background-color: currentColor;
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    ._show &:not(.hidden) {
        visibility: visible;
        opacity: 1;
        cursor: pointer;
    }
`;
