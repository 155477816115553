import { useState, useRef } from 'react';

type SubmitStatus = '' | 'success' | 'error' | 'registred' | 'virus';

export const useFormAlert = () => {
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('');
    const dataRef = useRef<any>(null);

    const closeAlert = () => {
        setSubmitStatus('');
        dataRef.current = null;
    };

    return { submitStatus, setSubmitStatus, dataRef, closeAlert };
};
