import React, { SyntheticEvent } from 'react';
import { useRouter } from 'next/router';
import Logo from '@/icons/logo-header-white.svg';
import LogoEn from '@/icons/logo-en-header-white.svg';
import Angle from '@/icons/cards/new/angle.svg';
import { SvgElements } from '@/helpers/icons';
import Download from '@/icons/new/download.svg';

import {
    Container,
    FooterLogo,
    FooterAddress,
    FooterContactsDocs,
    FooterContactsPhones,
    FooterContactsSocials,
    FooterContactsSocialContainer,
    FooterAngle,
    FooterContactsWrapper
} from './FooterContacts.styled';
import { FooterI } from '@/interfaces';

interface FooterContactsProps extends FooterI {
    className?: string;
}

const createPhoneLink = (href: string, template: string) => {
    return template.replace('#', href);
};

const FooterContacts: React.FC<FooterContactsProps> = ({ phones, address, docs, socials, className }) => {
    const phoneLinkTemplate = 'tel:#';
    const router = useRouter();

    const handleRouter = (e: SyntheticEvent) => {
        e.preventDefault();
        if (router.pathname === '/') return;
        router.push('/');
    };

    return (
        <Container className={className}>
            <FooterLogo router={router.pathname !== '/'} onClick={handleRouter}>
                {router.locale === 'ru' ? <Logo /> : <LogoEn />}
            </FooterLogo>
            <FooterContactsWrapper>
                {phones && (
                    <FooterContactsPhones>
                        {phones.map(({ href, text }, i) => (
                            <a key={i} href={createPhoneLink(href, phoneLinkTemplate)}>
                                {text}
                            </a>
                        ))}
                    </FooterContactsPhones>
                )}

                {address && <FooterAddress dangerouslySetInnerHTML={{ __html: address }} />}
            </FooterContactsWrapper>
            {socials && (
                <FooterContactsSocials>
                    {socials.map(({ href, links, name, text }, i) =>
                        links ? (
                            <FooterContactsSocialContainer key={i}>
                                {i === 0 && SvgElements[name]}
                                <ul>
                                    {links.map(({ text, href }, i) => (
                                        <li key={i}>
                                            <a href={href} target="_blank" rel="noreferrer">
                                                {text}
                                                {SvgElements['arrowRight']}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </FooterContactsSocialContainer>
                        ) : (
                            <a href={href} target="_blank" rel="noreferrer" key={i}>
                                {SvgElements[name]}
                                {text}
                            </a>
                        )
                    )}
                </FooterContactsSocials>
            )}

            {docs && (
                <FooterContactsDocs>
                    {docs.map((link, i) => (
                        <a key={i} href={link.href} download={link.download} target={link.target}>
                            {link.text}
                            <div>
                                <Download />
                            </div>
                        </a>
                    ))}
                </FooterContactsDocs>
            )}

            <FooterAngle>
                <Angle />
            </FooterAngle>
        </Container>
    );
};

export default FooterContacts;
