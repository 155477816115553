import { color, font, mediaBreakpointDown, mediaBreakpointUp, offset } from '@/style/mixins';
import styled from 'styled-components';
import { BtnSubmit, Container } from '@/components/ui/BaseFormCommon/BaseFormCommon.styled';

export const ContainerSubscribeForm = styled(Container)`
    padding: 0;
    border-radius: 0;

    form {
        display: flex;
        flex-direction: column;
        ${offset('l', 'gap')};
    }

    input {
        ${mediaBreakpointUp('xxl')} {
            width: 389px;
        }
    }

    ${BtnSubmit} {
        margin: 0;
    }
`;

export const WrapperBtnText = styled.div`
    display: flex;
    flex-direction: row;
    ${offset('xs', 'gap')};

    ${mediaBreakpointDown('md')} {
        flex-direction: column;
    }

    ${mediaBreakpointUp('md')} {
        align-items: center;
    }

    ${mediaBreakpointUp('xl')} {
        align-items: flex-start;
    }
`;

export const WrapperText = styled.div`
    ${font('text4')};

    * {
        display: inline-block;
    }

    a,
    p {
        color: ${color('DeepBlue/400')};
        opacity: 0.4;
    }
`;
