/**
 * @description Сэмплы компонента Tags
 */
const SERVICE_BLOCKS_INTERACTION_TAGS = {
    tags: {
        event: 'services-block-interaction',
        category: 'services',
        actions: [{ key: 'click-selector', value: null }]
    }
};
const PROPERTY_SALE_INTERACTION_TAGS = {
    tags: {
        event: 'property-sale-interaction',
        category: 'property-sale',
        actions: [{ key: 'property-customer-type-selector', value: null }]
    }
};
/**
 * @description Сэмплы компонента ServicesCardsInner
 */
const SERVICE_BLOCKS_INTERACTION_CARDS = {
    mainCard: {
        event: 'services-block-interaction',
        category: 'services',
        actions: [{ key: 'click-block-service', value: null }]
    },
    cards: {
        event: 'services-block-interaction',
        category: 'services',
        actions: [{ key: 'click-service', value: null }]
    } // В будущем надо будет добавить еще клик по кнопке в карточке
};
/**
 * @description Экшены кнопок
 */
const CLICK_BLOCK_ACTION = [
    { key: 'click-block', value: null },
    { key: 'placement_id', value: 'eudcation main' }
];

/**
 * @description Экшены форм
 */
const FORMS_REQUEST_SUCCESS_ACTIONS = [
    { key: 'success-request', value: true },
    { key: 'form_id', value: null },
    { key: 'request_id', value: null }
];

const FORMS_LEAVE_QUESTION_ACTIONS = [
    { key: 'click-leave-question', value: true },
    { key: 'form_id', value: null },
    { key: 'request_id', value: null }
];

const FORMS_COURSE_ID_ACTIONS = [
    { key: 'form_id', value: null },
    { key: 'request_id', value: null },
    { key: 'course_id', value: null }
];

const COURSE_ID_REGISTRATE_ACTIONS = [{ key: 'click-registrate', value: null }];

const FORMS_COURSE_ID_REGISTRATE_ACTIONS = [
    ...COURSE_ID_REGISTRATE_ACTIONS,
    { key: 'form_id', value: null },
    { key: 'request_id', value: null },
    { key: 'course_id', value: null }
];

/**
 * @description Сэмплы форм
 */
const ROSNEFT_FORMS_SAMPLES = {
    form: {
        event: 'rosneft-form-request-success',
        category: 'rosneft',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const ROSNEFT_PARTNERSHIP_FORMS_SAMPLES = {
    form: {
        event: 'rosneft-form-request-success-for-partnership',
        category: 'rosneft',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const INTERRAO_FORMS_SAMPLES_INTERACTION = {
    form: {
        event: 'inter-rao-interaction',
        category: 'inter-rao',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const INTERRAO_FORMS_SAMPLES_SUCCESS = {
    form: {
        event: 'inter-rao-request-success',
        category: 'inter-rao',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const CLIENT_CENTER_FORMS_SAMPLES = {
    form: {
        event: 'client-center-request-success',
        category: 'client-center',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const SERVICES_BLOCK_FORMS_SAMPLES = {
    form: {
        event: 'services-block-request-success',
        category: 'services',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const FINANCE_BLOCK_FORMS_SAMPLES = {
    form: {
        event: 'finance-block-request-success',
        category: 'finance',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const SERVICE_BLOCK_FORMS_SAMPLES = {
    form: {
        event: 'services-block-interaction',
        category: 'services',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const EDUCATION_BLOCK_INTERACTION = {
    formCourseId: {
        event: 'education-block-interaction',
        category: 'education',
        actions: FORMS_COURSE_ID_ACTIONS
    },
    bannerButtonText: {
        event: 'education-block-interaction',
        category: 'education',
        actions: CLICK_BLOCK_ACTION
    },
    actualCards: {
        event: 'education-block-interaction',
        category: 'education',
        actions: CLICK_BLOCK_ACTION
    }
};

const EDUCATION_COURSE_BLOCK_INTERACTION = {
    leadButtonCourseId: {
        event: 'education-block-interaction',
        category: 'education',
        actions: COURSE_ID_REGISTRATE_ACTIONS
    },
    formRegistration: {
        event: 'education-block-interaction',
        category: 'education',
        actions: FORMS_COURSE_ID_REGISTRATE_ACTIONS
    },
    formLeaveQuestion: {
        event: 'education-block-interaction',
        category: 'education',
        actions: FORMS_LEAVE_QUESTION_ACTIONS
    }
};

const CALLBACK_FORMS_SAMPLES = {
    form: {
        event: 'callback-call-request-success',
        category: 'callback',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const ABOUT_COMPANY_FORMS_SAMPLES = {
    form: {
        event: 'about-company-form-request',
        category: 'about-company',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const MAIL_SUBSCRIBE_SAMPLES = {
    form: {
        event: 'about-company-mailing-subscribed',
        category: 'about-company',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const CONTACT_MANAGER_FORM_SAMPLES = {
    form: {
        event: '223-fz-click-contact-manager',
        category: '223-fz',
        actions: {}
    }
};

const BANK_GUARANTEE_FORM_SAMPLES = {
    allForm: {
        event: 'bg_submit_all_forms',
        category: '',
        actions: {}
    },
    expertForm: {
        event: 'bg_submit_expert',
        category: '',
        actions: {}
    },
    guaranteeForm: {
        event: 'bg_submit_guarantee',
        category: '',
        actions: {}
    }
};

/**
 * @description Сэмплы blockWithCards
 */
const CLIENTS_BLOCK_CARDS_ACTIONS = [{ key: 'click-block', value: null }];

const CLIENTS_BLOCK_CARDS_SAMPLES = {
    blockWithCards: {
        event: 'client-center-request-success',
        category: 'client-center',
        actions: CLIENTS_BLOCK_CARDS_ACTIONS
    }
};

const UVS_BLOCK_CARDS_SAMPLES = {
    blockWithCards: {
        event: 'uvs-interaction',
        category: 'uvs',
        actions: CLIENTS_BLOCK_CARDS_ACTIONS
    }
};

const FINANCE_SERVICES_SAMPLES = {
    headerLinks: {
        event: 'finance-click-category',
        category: 'finance',
        actions: {}
    },
    leadInnerLink: {
        event: 'finance-click-button',
        category: 'finance',
        actions: 'click-button'
    },
    interactionForm: {
        event: 'finance-interaction-form',
        category: 'finance',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    },
    formFilling: {
        event: 'finance-interaction-form',
        category: 'finance',
        actions: [{ key: 'interaction-form', value: null }]
    },
    serviceBenefitsLink: {
        event: 'finance-click-button',
        category: 'finance',
        actions: [{ key: 'click-button' }]
    }
};

const INDEPENDENT_GUARANTEE_SAMPLES = {
    headerLinks: {
        event: 'finance-bg-click-category',
        category: 'finance-bg',
        actions: {}
    },
    leadInnerLink: {
        event: 'finance-bg-click-button',
        category: 'finance-bg',
        actions: 'click-button'
    },
    serviceBenefitsLink: {
        event: 'finance-bg-click-button',
        category: 'finance-bg',
        actions: [{ key: 'click-button' }]
    },
    serviceBenefitsCards: {
        event: 'finance-bg-click-guarantee',
        category: 'finance-bg',
        actions: 'click-guarantee'
    },
    bannerSlider: {
        event: 'finance-bg-click-button',
        category: 'finance-bg',
        actions: 'click-button'
    },
    primaryCardsSliderSection: {
        event: 'finance-bg-choose',
        category: 'finance-bg',
        actions: {}
    },
    bannerSlider1: {
        event: 'finance-bg-click-loyalty',
        category: 'finance-bg',
        actions: 'click-loyalty'
    },
    bannersComposition: {
        event: 'finance-bg-click-more',
        category: 'finance-bg',
        actions: 'click-more'
    },
    interactionForm: {
        event: 'finance-bg-interaction-form',
        category: 'finance-bg',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    },
    formFilling: {
        event: 'finance-bg-interaction-form',
        category: 'finance-bg',
        actions: [{ key: 'interaction-form', value: null }]
    }
};

const INDEPENDENT_GUARANTEE_SUPPORT_SECTION = {
    card0: {
        event: 'finance-bg-click-faq',
        category: 'finance-bg',
        actions: {}
    },
    card1: {
        event: 'finance-bg-click-rules',
        category: 'finance-bg',
        actions: {}
    },
    card2: {
        event: 'finance-bg-click-question',
        category: 'finance-bg',
        actions: {}
    }
};

const INDEX_CONTENT_TABS_FOR_SUPPLIERS = {
    tab0: {
        event: 'suppliers-block-popular-click-banner',
        category: 'suppliers-block',
        actions: 'click-banner'
    },
    tab1: {
        event: 'suppliers-block-education-click-button',
        category: 'suppliers-block',
        actions: 'click-button'
    },
    tab2: {
        event: 'suppliers-block-bg-click-button',
        category: 'suppliers-block',
        actions: 'click-button'
    },
    tab3: {
        event: 'suppliers-block-prep-click-button',
        category: 'suppliers-block',
        actions: 'click-button'
    },
    tab4: {
        event: 'suppliers-block-accomp-click-button',
        category: 'suppliers-block',
        actions: 'click-button'
    }
};

const INDEX_NEWS_SECTION = {
    newsTab0: {
        event: 'press-click-news',
        category: 'main',
        actions: 'click-news'
    },
    newsTab1: {
        event: 'media-click-news',
        category: 'main',
        actions: 'click-news'
    },
    newsTab2: {
        event: 'important-click-news',
        category: 'main',
        actions: 'click-news'
    },
    newsTab3: {
        event: 'announcement-click-news',
        category: 'main',
        actions: 'click-news'
    }
};

const BANNERS_SECTION = {
    banner0: {
        event: 'purchases-click-banner',
        category: 'purchases',
        actions: {}
    },
    banner1: {
        event: 'objects-click-banner',
        category: 'objects',
        actions: {}
    }
};

const TRADE_SECTIONS_SAMPLES = {
    bannerButtonText: {
        event: 'trade-sections-click-more',
        category: 'trade-sections',
        actions: {}
    },
    interactionForm: {
        event: 'trade-sections-interaction-form',
        category: 'trade-sections',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    },
    formClickSubmitBtn: {
        event: 'trade-sections-click-form',
        category: 'trade-sections',
        actions: [{ key: 'click-button', value: null }]
    }
};

const CLIENTS_SAMPLES = {
    interactionForm: {
        event: 'clients-interaction-form',
        category: 'clients',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const SERVICE_SAMPLES = {
    interactionForm: {
        event: 'services-interaction-form',
        category: 'services',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    },
    formClickSubmitBtn: {
        event: 'services-click-form',
        category: 'services',
        actions: [{ key: 'click-form', value: null }]
    }
};

/** */
const SAMPLES = {
    SERVICE_BLOCKS_INTERACTION_TAGS,
    PROPERTY_SALE_INTERACTION_TAGS,
    SERVICE_BLOCKS_INTERACTION_CARDS,
    ROSNEFT_FORMS_SAMPLES,
    ROSNEFT_PARTNERSHIP_FORMS_SAMPLES,
    INTERRAO_FORMS_SAMPLES_INTERACTION,
    INTERRAO_FORMS_SAMPLES_SUCCESS,
    CLIENT_CENTER_FORMS_SAMPLES,
    SERVICES_BLOCK_FORMS_SAMPLES,
    FINANCE_BLOCK_FORMS_SAMPLES,
    SERVICE_BLOCK_FORMS_SAMPLES,
    EDUCATION_BLOCK_INTERACTION,
    EDUCATION_COURSE_BLOCK_INTERACTION,
    CALLBACK_FORMS_SAMPLES,
    ABOUT_COMPANY_FORMS_SAMPLES,
    MAIL_SUBSCRIBE_SAMPLES,
    CLIENTS_BLOCK_CARDS_SAMPLES,
    UVS_BLOCK_CARDS_SAMPLES,
    CONTACT_MANAGER_FORM_SAMPLES,
    BANK_GUARANTEE_FORM_SAMPLES,
    FINANCE_SERVICES_SAMPLES,
    INDEPENDENT_GUARANTEE_SAMPLES,
    INDEPENDENT_GUARANTEE_SUPPORT_SECTION,
    INDEX_CONTENT_TABS_FOR_SUPPLIERS,
    INDEX_NEWS_SECTION,
    BANNERS_SECTION,
    TRADE_SECTIONS_SAMPLES,
    CLIENTS_SAMPLES,
    SERVICE_SAMPLES
};

export default SAMPLES;
