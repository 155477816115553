import { useState, useEffect, useRef } from 'react';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import GlobalStyle from '@/style/globalStyles';
import '@/style/fonts.css';
import 'element-closest-polyfill';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

dayjs.extend(customParseFormat);

import { Provider, useDispatch, useSelector } from 'react-redux';
import { useStore } from '@/redux/store';

import useIsIE11 from '@/hooks/useIsIE11';
import { setIsInitialRouterPage } from '@/redux/pageTransition/pageTransitionSlice';
import { RootState } from '@/redux/rootReducer';
import { Router } from 'next/router';
import { getHeaderBanners, getSideBanners } from '@/helpers/bannerHelpers';
import { setHeaderBanners, setSideBanners } from '@/redux/banners/bannersSlice';
import { setSectionsCustomers } from '@/redux/ourCustomers/ourCustomersSlice';
import { getRosneftPartitionsFetch, checkBuild, getPopups } from '@/lib/api';
import { setNewsDataExist } from '@/redux/rosneftNewsCheck/rosneftNewsCheckSlice';
import { getCustomersList } from '@/helpers/data/getCustomers';

import dynamic from 'next/dynamic';
import NotSupportedIE from '@/components/blocks/NotSupported/NotSupportedIE';
import { usePrevPageScrollY } from '@/hooks/usePrevPageScrollY';
import ErrorBoundary from '@/components/common/ErrorBoundary/ErrorBoundary';
import { useCheckBrowserVersion } from '@/hooks/useCheckBrowserVersion';
import Head from 'next/head';
import { useDOMStyles } from '@/hooks/useDOMStyles';
import { IPromotionPopup } from '@/componentsNew/ui/PromotionPopup/PromotionPopup';

const PageTransition = dynamic(() => import('@/components/common/PageTransition/PageTransition'));
const CookieMessage = dynamic(() => import('@/components/blocks/CookieMessage/CookieMessage'));
const PromotionPopup = dynamic(() => import('@/componentsNew/ui/PromotionPopup/PromotionPopup'));

interface InitialProps {
    router: Router;
}

interface CustomPageProps {
    initialReduxState: RootState;
}

const GetInitialProps = ({ router }: InitialProps) => {
    const { locale } = router;
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headerBanners = await getHeaderBanners(locale || 'ru');
                const sideBanners = await getSideBanners(locale || 'ru');

                dispatch(setHeaderBanners(headerBanners));
                dispatch(setSideBanners(sideBanners));
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [dispatch, locale]);

    useEffect(() => {
        const fetch = async () => {
            const customersSlugs = await getCustomersList();
            dispatch(setSectionsCustomers(customersSlugs));
        };
        fetch();
    }, []);

    return null;
};

const InitialRouterPageObserver = ({ router }: InitialProps) => {
    const prevRouterPath = useRef<string>(router.asPath);
    const dispatch = useDispatch();
    const isInitialRouterPage = useSelector((state: RootState) => state.pageTransition.isInitialRouterPage);

    useEffect(() => {
        if (router.asPath.includes('/rosneft')) {
            const checkAnnouncement = async () => {
                const Partitions = (await getRosneftPartitionsFetch()).data.data;
                return Partitions;
            };

            checkAnnouncement().then(function (value) {
                if (value) {
                    dispatch(setNewsDataExist(true));
                } else {
                    dispatch(setNewsDataExist(false));
                }
            });
        }
    }, [router.pathname, router.locale]);

    useEffect(() => {
        if (prevRouterPath.current !== router.asPath && isInitialRouterPage) {
            dispatch(setIsInitialRouterPage(false));
        } else if (prevRouterPath.current === router.asPath) {
            dispatch(setIsInitialRouterPage(true));
        }
    }, [router.asPath, dispatch, isInitialRouterPage, router.locale]);

    return null;
};

const MyApp = ({ Component, pageProps, router }: AppProps<CustomPageProps>) => {
    const [promotionPopups, setPromotionPopups] = useState<IPromotionPopup[] | null>(null);

    useEffect(() => {
        const fetchPopup = async () => {
            try {
                const {
                    data: { data: popups }
                } = await getPopups('promotion', locale);
                setPromotionPopups(popups);
            } catch (error) {
                console.error('Ошибка в получении Popup:', error);
            }
        };
        fetchPopup();
    }, [router.locale]);

    useDOMStyles(); // for webvisor yandex metrika

    useCheckBrowserVersion();

    const { asPath, locale } = router;
    dayjs.locale(locale);
    const store = useStore(pageProps.initialReduxState);
    const refObjectFitImages = useRef<any>(null);
    const isIE11 = useIsIE11();

    let isFrame = false;
    const framePages = [
        '/portal_khabarovsk/frame',
        '/silovyi_machine/frame',
        '/inter_rao/tomsk1',
        '/inter_rao/tomsk2',
        '/zakupki_jtk/frame'
    ];

    usePrevPageScrollY(router);

    useEffect(() => {
        const checkBuildStatus = async () => {
            try {
                if (process.env.NODE_ENV !== 'development') {
                    const { data } = await checkBuild();
                    if (!data.status) await router.push('/tech');
                }
            } catch (e) {
                await router.push('/tech');
            }
        };

        checkBuildStatus();
    }, []);

    useEffect(() => {
        document.body?.classList?.add('ready');

        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }

        document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.body.clientWidth}px`);
    }, []);

    useEffect(() => {
        if (isIE11) {
            import('object-fit-images').then((objectFitImages) => {
                refObjectFitImages.current = objectFitImages.default;
                objectFitImages.default();
            });
        }
    }, [isIE11]);

    useEffect(() => {
        if (refObjectFitImages.current) refObjectFitImages.current();
    }, [asPath]);

    if (framePages.filter((item) => asPath.includes(item)).length > 0) {
        isFrame = true;
    }
    return (
        <>
            <Head>
                <link rel="alternate" hrefLang="ru" href={`https://www.tektorg.ru${asPath}`} />
                <link rel="alternate" hrefLang="en" href={`https://www.tektorg.ru/en${asPath}`} />
            </Head>
            <Provider store={store}>
                <GlobalStyle />
                <PageTransition />
                <InitialRouterPageObserver router={router} />
                <GetInitialProps router={router} />
                <NotSupportedIE />
                <ErrorBoundary router={router}>
                    <Component {...pageProps} />
                </ErrorBoundary>

                {!isFrame && (
                    <>
                        <CookieMessage />

                        {/* /* <!-- webim button generation date: 2023-01-16 version: 10.4.136 --> */}
                        <a className="webim_button" href="#" rel="webim" style={{ display: 'none' }}>
                            <img alt={'webim'} width={80} height={80} src="https://tektorgru.webim.ru/button.php?location=departments" />
                        </a>

                        {promotionPopups ? <PromotionPopup popups={promotionPopups} /> : null}
                    </>
                )}
            </Provider>
        </>
    );
};

export default appWithTranslation(MyApp as any);
