import styled from 'styled-components';
import { color, font, offset, hover, mediaBreakpointUp } from '@/style/mixins';

export const Container = styled.div`
    position: absolute;
    z-index: 10;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    ${offset('xs', 'padding-top')};
    ${offset('xs', 'padding-bottom')};
    padding-left: 20px;
    padding-right: 20px;

    ${font('text2-sb')};
    color: ${color('text-primary')};
    background-color: ${color('white')};
    width: 100vw;
`;

export const ContentWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

export const Text = styled.div`
    flex: 1;
    ${offset('xs', 'margin-left')};
    ${offset('xs', 'margin-right')};

    a {
        color: ${color('link-accent-default')};
        transition: color 0.3s ease-in-out;

        ${hover(`
        color: ${color('link-accent-hover')};
    `)};
    }
`;

export const CloseBtn = styled.button`
    padding: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        width: 20px;
        height: 20px;
    }

    ${hover(`
        opacity: 0.6;
    `)};

    svg {
        width: inherit;
        height: inherit;
        fill: ${color('ui-icon-default')};
    }
`;
