import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * @description Возвращает объект содержащий свойство titles, которое содержит объект с title страницы в двух форматах:
 * title: 'Обучение по тендерам и закупкам от ЭТП ТЭК-Торг'
 * pageTitle: 'ОБУЧЕНИЕ-ПО-ТЕНДЕРАМ-И-ЗАКУПКАМ-ОТ-ЭТП-ТЭК-ТОРГ'
 */
export const useServicePage = () => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];

    const [titles, setTitles] = useState({
        title: '',
        pageTitle: ''
    });

    useEffect(() => {
        requestAnimationFrame(() => {
            const serviceTitle = document?.title.replace(/\| ТЭК-Торг/gi, '').trim();
            const servicepageTitle = serviceTitle?.toUpperCase().replace(/ /gi, '-');

            setTitles((prev) => ({ ...prev, title: serviceTitle, pageTitle: servicepageTitle }));
        });
    }, [pathname]);

    return { titles };
};
