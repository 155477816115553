import Vk from '@/icons/vk.svg';
import Vc from '@/icons/vc.svg';
import Yt from '@/icons/yt.svg';
import Apple from '@/icons/apple.svg';
import GooglePlay from '@/icons/googleplay.svg';
import Telegram from '@/icons/telegram.svg';
import Like from '@/icons/like.svg';
import Dislike from '@/icons/dislike.svg';
import VkFrame from '@/icons/vkFrame.svg';
import TelegramFrame from '@/icons/telegramFrame.svg';
import Tektorg from '@/icons/tektorg.svg';

// Иконки для секций в LeadInner
import LeadInner1 from '@/icons/new/lead-inner/1.svg';
import BagLeadInner from '@/icons/new/lead-inner/bag.svg';
import FileLeadInner from '@/icons/new/lead-inner/file.svg';
// UI LeadInner
import SingleWindow from '@/icons/new/lead-inner/single_window.svg';
import ImportSubs from '@/icons/new/lead-inner/import_subs.svg';
import Qualification from '@/icons/new/lead-inner/qualification.svg';
import Suppliers from '@/icons/new/lead-inner/suppliers.svg';
import ForeinSuppliers from '@/icons/new/lead-inner/forein_suppliers.svg';
import Questionnaire from '@/icons/new/lead-inner/questionnaire.svg';
import Tariffs from '@/icons/new/lead-inner/tariffs.svg';
import Docs from '@/icons/new/lead-inner/docs.svg';
import CustomersBenefits from '@/icons/new/lead-inner/customers_benefits.svg';
import ServicesFacilities from '@/icons/new/lead-inner/services_facilities.svg';
import ProcurementProcedures from '@/icons/new/lead-inner/procurement_procedures.svg';
import SupportCenter from '@/icons/new/lead-inner/support_center.svg';
import FinServices from '@/icons/new/lead-inner/fin_services.svg';
import News from '@/icons/new/lead-inner/news.svg';
import Tenders from '@/icons/new/lead-inner/tenders.svg';
import Lising from '@/icons/new/lead-inner/lising.svg';
import IndependentGuarantee from '@/icons/new/lead-inner/independent_guarantee.svg';
import FactoringLead from '@/icons/new/lead-inner/factoring.svg';
import SpecialAccount from '@/icons/new/lead-inner/special_account.svg';
import EducationMaterial from '@/icons/new/lead-inner/education.svg';
import Video from '@/icons/new/lead-inner/video.svg';

// cards
import Cart from '@/icons/cards/cart.svg';
import CartMoney from '@/icons/cards/cartMoney.svg';
import CatFull from '@/icons/cards/cartFull.svg';

import ClockArrow from '@/icons/cards/clockArrow.svg';
import Finger from '@/icons/cards/finger.svg';
import Piece from '@/icons/cards/piece.svg';

import Blank from '@/icons/cards/blank.svg';
import Rocket from '@/icons/cards/rocket.svg';
import Checkbox from '@/icons/cards/checkbox.svg';
import Case from '@/icons/cards/case.svg';
import Signature from '@/icons/cards/signature.svg';
import List from '@/icons/cards/list.svg';
import Case2 from '@/icons/cards/case2.svg';
import Notification from '@/icons/cards/notification.svg';
import Stamp from '@/icons/cards/stamp.svg';
import FinancialServices from '@/icons/cards/financialServices.svg';
import Phone from '@/icons/cards/phone.svg';
import DocumentFile from '@/icons/cards/documentFile.svg';
import Timeout from '@/icons/cards/timeout.svg';
import Conversation from '@/icons/cards/conversation.svg';
import Registry from '@/icons/cards/registry.svg';
import Search from '@/icons/search.svg';
import Person from '@/icons/cards/person.svg';
import Innovation from '@/icons/cards/innovation.svg';
import Angle from '@/icons/cards/new/angle.svg';

import Feedback from '@/icons/feedback.svg';
import File from '@/icons/file.svg';
import CheckMark from '@/icons/check-mark.svg';
import CheckMark2 from '@/icons/check-mark-2.svg';
import ViewCard from '@/icons/new/viewCard.svg';
import Calendar from '@/icons/calendar.svg';
import ViewTable from '@/icons/new/viewTable.svg';
import FileDownload from '@/icons/fileDownload.svg';
import FileDownloadBlack from '@/icons/fileDownloadBlack.svg';
import Globe from '@/icons/globe.svg';

import Bag from '@/icons/commercialProcurement/bag.svg';
import CheckMark3 from '@/icons/commercialProcurement/check-mark-3.svg';
import DocumentChecked from '@/icons/commercialProcurement/document-checked.svg';
import DocumentList from '@/icons/commercialProcurement/document-list.svg';
import DocumentPencil from '@/icons/commercialProcurement/document-pencil.svg';
import JudgesGavel from '@/icons/commercialProcurement/judges-gavel.svg';
import Rubles from '@/icons/cards/rubles.svg';

import Rosneft from '@/icons/sectionsLogos/rosneft.svg';

import Rosneft2 from '@/icons/sectionsLogos/rosneft2.svg';
import StateProcurements from '@/icons/sectionsLogos/stateProcurements.svg';
import InterRAO from '@/icons/sectionsLogos/interRAO.svg';
import CommercialPurchases from '@/icons/sectionsLogos/commercialPurchases.svg';
import Sale from '@/icons/sectionsLogos/sale.svg';
import Lcabinet from '@/icons/sectionsLogos/lcabinet.svg';
import Lcabinetlight from '@/icons/sectionsLogos/lcabinetlight.svg';
import Lcabinet2 from '@/icons/sectionsLogos/lcabinet2.svg';
import Market from '@/icons/sectionsLogos/market.svg';
import Rzd from '@/icons/sectionsLogos/rzd.svg';
import Spb from '@/icons/sectionsLogos/spb.svg';
import Ncm from '@/icons/sectionsLogos/belIcon.svg';
import CommercialPurchases2 from '@/icons/sectionsLogos/commercialPurchases2.svg';
import Piece2 from '@/icons/sectionsLogos/piece2.svg';
import Print2 from '@/icons/sectionsLogos/print2.svg';
import Market2 from '@/icons/sectionsLogos/market2.svg';
import Spb2 from '@/icons/sectionsLogos/spb2.svg';
import Oboronenergo from '@/icons/sectionsLogos/oboronenergo.svg';
import Uz from '@/icons/sectionsLogos/uz.svg';
import Ppk from '@/icons/sectionsLogos/ppk.svg';
import Metro from '@/icons/sectionsLogos/metro.svg';
import Snt from '@/icons/sectionsLogos/snt.svg';
import Silmash from '@/icons/sectionsLogos/silmash.svg';
import Rmp from '@/icons/sectionsLogos/rmp.svg';
import Mosenergo from '@/icons/sectionsLogos/mosenergo.svg';
import DefaultIcon from '@/icons/sectionsLogos/default.svg';

import Document from '@/icons/documents/document.svg';

import Print from '@/icons/print.svg';

import RosneftFull from '@/icons/sidebarHeads/rosneft.svg';
import RosneftFullEn from '@/icons/sidebarHeads/rosneft_en.svg';
import FZ223 from '@/icons/sidebarHeads/fz223.svg';
import FZ223En from '@/icons/sidebarHeads/fz223_en.svg';
import ForClients from '@/icons/sidebarHeads/forClients.svg';
import ForClientsEn from '@/icons/sidebarHeads/forClients_en.svg';
import InterRAOFull from '@/icons/sidebarHeads/interRAO.svg';
import InterRAOFullEn from '@/icons/sidebarHeads/interRAO_en.svg';
import SaleFull from '@/icons/sidebarHeads/sale.svg';
import SaleFullEn from '@/icons/sidebarHeads/sale_en.svg';
import MarketFull from '@/icons/sidebarHeads/market.svg';
import MarketFullEn from '@/icons/sidebarHeads/market_en.svg';
import GovProcurement from '@/icons/sidebarHeads/govProcurement.svg';
import GovProcurementEn from '@/icons/sidebarHeads/govProcurement_en.svg';
import ServicesFull from '@/icons/sidebarHeads/services.svg';
import ServicesFullEn from '@/icons/sidebarHeads/services_en.svg';
import Realization from '@/icons/sidebarHeads/realization.svg';
import RealizationEn from '@/icons/sidebarHeads/realization_en.svg';
import CompanyFull from '@/icons/sidebarHeads/company.svg';
import CompanyFullEn from '@/icons/sidebarHeads/company_en.svg';
import AllProcedures from '@/icons/sidebarHeads/allProcedures.svg';
import AllProcedures2 from '@/icons/sidebarHeads/allProcedures2.svg';
import AllProceduresEn from '@/icons/sidebarHeads/allProcedures_en.svg';
import RegionProcedures from '@/icons/sidebarHeads/regionProcedures.svg';
import RegionProceduresEn from '@/icons/sidebarHeads/regionProcedures_en.svg';
import Organizators from '@/icons/sidebarHeads/organizators.svg';
import RzdProcurement from '@/icons/sidebarHeads/rzdProcurement.svg';
import RzdProcurementEn from '@/icons/sidebarHeads/rzdProcurement_en.svg';
import EnPlusGroup from '@/icons/sidebarHeads/enplusgroup.svg';

import BagBlue from '@/icons/pageDocuments/bagBlue.svg';
import BagBlue2 from '@/icons/pageDocuments/bagBlue2.svg';
import BuildingBlue from '@/icons/pageDocuments/buildingBlue.svg';
import BuildingGreen from '@/icons/pageDocuments/buildingGreen.svg';
import CartBlue from '@/icons/pageDocuments/cartBlue.svg';
import CaseBlue from '@/icons/pageDocuments/caseBlue.svg';
import FlagBlue from '@/icons/pageDocuments/flagBlue.svg';
import InterRao from '@/icons/pageDocuments/interRao.svg';
import EducationFull from '@/icons/sidebarHeads/education.svg';
import EducationFullEn from '@/icons/sidebarHeads/education_en.svg';
import Learning from '@/icons/cards/learning.svg';
import Bankomat from '@/icons/cards/bankomat.svg';
import Flag from '@/icons/cards/flag.svg';
import Files from '@/icons/cards/files.svg';
import GeneralDocs from '@/icons/pageDocuments/generalDocs.svg';
import Fz43 from '@/icons/pageDocuments/43fz.svg';
import Basket from '@/icons/pageDocuments/basket.svg';
import SaleColorful from '@/icons/pageDocuments/saleColorful.svg';
import RubIcon from '@/icons/pageDocuments/rubIcon.svg';
import Files2 from '@/icons/pageDocuments/files2.svg';
import Credit from '@/icons/services/credit.svg';
import CreditOnDogovor from '@/icons/services/creditOnDogovor.svg';
import SpecAcc from '@/icons/services/specAccount.svg';
import CreditOnProcurement from '@/icons/services/creditOnProcurement.svg';
import Factoring from '@/icons/services/factoring.svg';
import AcompanyTenders from '@/icons/services/acompanyTenders.svg';
import Icwe from '@/icons/services/icwe.svg';
import FZ223icon from '@/icons/sectionsLogos/223fz.svg';
import EdinoeOkno from '@/icons/services/edinoePOknoTeh.svg';
import Tkp from '@/icons/services/tkp.svg';
import Inovation from '@/icons/services/innovation.svg';
import Stonks from '@/icons/services/stonks.svg';
import Importozamewenie from '@/icons/services/importozamewenie.svg';
import Msp from '@/icons/services/msp.svg';
import Education from '@/icons/services/education.svg';
import PreTZ from '@/icons/services/preFindTZ.svg';
import PreFindProcurement from '@/icons/services/preFindProcurments.svg';

import CircleCheck from '@/icons/circleCheck.svg';
import CircleWarning from '@/icons/circleWarning.svg';

// sidebar mobile
import Services from '@/icons/sidebarMobile/services.svg';
import Fz223 from '@/icons/sidebarMobile/223Fz.svg';
import About from '@/icons/sidebarMobile/about.svg';
import EducationSM from '@/icons/sidebarMobile/education.svg';
import Clients from '@/icons/sidebarMobile/clients.svg';
import InterRAOSM from '@/icons/sidebarMobile/interRAO.svg';
import MarketSM from '@/icons/sidebarMobile/market.svg';
import Procedures from '@/icons/sidebarMobile/procedures.svg';
import ProceduresGrey from '@/icons/sidebarMobile/procedures-grey.svg';
import RosneftSM from '@/icons/sidebarMobile/rosneft.svg';
import RZDSM from '@/icons/sidebarMobile/rzd.svg';
import SaleSM from '@/icons/sidebarMobile/sale.svg';
import GovProcurementSM from '@/icons/sidebarMobile/43Fz.svg';
import RegionProceduresSM from '@/icons/sidebarMobile/regionProcedures.svg';
import BelIcon from '@/icons/sidebarMobile/belIcon.svg';

import Support from '@/icons/support.svg';
import Client from '@/icons/client.svg';
import LoupePlus from '@/icons/loupePlus.svg';

import Chrome from '@/icons/browsers/withoutText/chrome.svg';
import Edge from '@/icons/browsers/withoutText/edge.svg';
import Firefox from '@/icons/browsers/withoutText/firefox.svg';
import Opera from '@/icons/browsers/withoutText/opera.svg';
import Safari from '@/icons/browsers/withoutText/safari.svg';

import People from '@/icons/people.svg';
import Loupe from '@/icons/loupe.svg';
import ArrowUp from '@/icons/new/arrow-up.svg';
import ArrowRight from '@/icons/new/arrow-right.svg';
import TariffGift from '@/icons/new/tariff-gift.svg';

export const DEFAULT_SECTION_ICON = 'defaultSectionIcon';

export const SvgElements: { [key in string]: JSX.Element } = {
    // Иконки для секций в LeadInner
    leadInner1: <LeadInner1 />,
    bagLeadInner: <BagLeadInner />,
    fileLeadInner: <FileLeadInner />,

    singleWindow: <SingleWindow />,
    importSubs: <ImportSubs />,
    qualification: <Qualification />,
    suppliers: <Suppliers />,
    foreinSuppliers: <ForeinSuppliers />,
    questionnaire: <Questionnaire />,
    tariffs: <Tariffs />,
    docs: <Docs />,
    customersBenefits: <CustomersBenefits />,
    servicesFacilities: <ServicesFacilities />,
    procurementProcedures: <ProcurementProcedures />,
    supportCenter: <SupportCenter />,
    finServices: <FinServices />,
    news: <News />,
    tenders: <Tenders />,
    lising: <Lising />,
    independentGuarantee: <IndependentGuarantee />,
    factoringLead: <FactoringLead />,
    specialAccount: <SpecialAccount />,
    educationMaterial: <EducationMaterial />,
    video: <Video />,

    // sidebar mobile
    services: <Services />,
    fz223SM: <Fz223 />,
    about: <About />,
    educationSm: <EducationSM />,
    clients: <Clients />,
    interRAOSM: <InterRAOSM />,
    marketSM: <MarketSM />,
    procedures: <Procedures />,
    proceduresGrey: <ProceduresGrey />,
    rosneftSM: <RosneftSM />,
    rzdSM: <RZDSM />,
    saleSM: <SaleSM />,
    govProcurementSM: <GovProcurementSM />,
    regionProceduresSM: <RegionProceduresSM />,
    belICon: <BelIcon />,

    // socials
    vk: <Vk />,
    vc: <Vc />,
    youtube: <Yt />,
    apple: <Apple />,
    googleplay: <GooglePlay />,
    telegram: <Telegram />,
    like: <Like />,
    dislike: <Dislike />,
    vkFrame: <VkFrame />,
    telegramFrame: <TelegramFrame />,
    tektorg: <Tektorg />,

    // cards
    cart: <Cart />,
    cartMoney: <CartMoney />,
    cartFull: <CatFull />,
    clockArrow: <ClockArrow />,
    finger: <Finger />,
    piece: <Piece />,

    blank: <Blank />,
    rocket: <Rocket />,
    checkbox: <Checkbox />,
    case: <Case />,

    feedback: <Feedback />,
    file: <File />,
    checkMark: <CheckMark />,
    checkMark2: <CheckMark2 />,
    calendar: <Calendar />,
    signature: <Signature />,
    list: <List />,
    case2: <Case2 />,
    viewCard: <ViewCard />,
    viewTable: <ViewTable />,
    notification: <Notification />,
    stamp: <Stamp />,
    financialServices: <FinancialServices />,
    phone: <Phone />,
    documentFile: <DocumentFile />,
    timeout: <Timeout />,
    conversation: <Conversation />,
    registry: <Registry />,
    search: <Search />,
    person: <Person />,
    innovation: <Innovation />,
    fileDownload: <FileDownload />,
    fileDownload2: <FileDownloadBlack />,
    globe: <Globe />,

    bag: <Bag />,
    checkMark3: <CheckMark3 />,
    documentChecked: <DocumentChecked />,
    documentList: <DocumentList />,
    documentPencil: <DocumentPencil />,
    judgesGavel: <JudgesGavel />,
    rubles: <Rubles />,

    learning: <Learning />,
    bankomat: <Bankomat />,
    flag: <Flag />,
    files: <Files />,

    angle: <Angle />,

    // section logos
    rosneft: <Rosneft />,
    rosnefttkp: <Rosneft />,
    rosneft_selling: <Rosneft />,
    interrao: <InterRAO />,
    '44fz': <Fz43 />,
    fz223: <FZ223 />,
    zakupki: <Lcabinet />,
    document: <Document />,
    rzd: <Rzd />,
    spb: <Spb />,
    oboronenergo: <Oboronenergo />,
    upravlenie_zakazchika: <Uz />,
    portone: <Ppk />,
    metro_spb: <Metro />,
    sntrans: <Snt />,
    silovyi_machine: <Silmash />,
    rosmorport: <Rmp />,
    mosenergo: <Mosenergo />,
    enplusgroup: <EnPlusGroup />,
    ncm: <Ncm />,

    rosneft2: <Rosneft2 />,
    stateProcurements: <StateProcurements />,
    interRAO: <InterRAO />,
    commercialPurchases: <CommercialPurchases />,
    sale: <Sale />,
    lcabinet: <Lcabinetlight />,
    lcabinet2: <Lcabinet2 />,
    market: <Market />,

    print: <Print />,

    spb2: <Spb2 />,
    commercialPurchases2: <CommercialPurchases2 />,
    piece2: <Piece2 />,
    print2: <Print2 />,
    market2: <Market2 />,

    [DEFAULT_SECTION_ICON]: <DefaultIcon />,

    // sidebar heads
    rosneftFull: <RosneftFull />,
    RosneftFullEn: <RosneftFullEn />,
    forClients: <ForClients />,
    forClientsEn: <ForClientsEn />,
    interRAOFull: <InterRAOFull />,
    interRAOFullEn: <InterRAOFullEn />,
    saleFull: <SaleFull />,
    saleFullEn: <SaleFullEn />,
    marketFull: <MarketFull />,
    marketFullEn: <MarketFullEn />,
    fz223icon: <FZ223icon />,
    fz223En: <FZ223En />,
    govProcurement: <GovProcurement />,
    govProcurementEn: <GovProcurementEn />,
    servicesFull: <ServicesFull />,
    servicesFullEn: <ServicesFullEn />,
    realization: <Realization />,
    realizationEn: <RealizationEn />,
    companyFull: <CompanyFull />,
    companyFullEn: <CompanyFullEn />,
    educationFull: <EducationFull />,
    educationFullEn: <EducationFullEn />,
    allProcedures: <AllProcedures />,
    allProcedures2: <AllProcedures2 />,
    allProceduresEn: <AllProceduresEn />,
    regionProcedures: <RegionProcedures />,
    RegionProceduresEn: <RegionProceduresEn />,
    organizators: <Organizators />,
    rzdProcurement: <RzdProcurement />,
    rzdProcurementEn: <RzdProcurementEn />,

    // page documents
    bagBlue: <BagBlue />,
    bagBlue2: <BagBlue2 />,
    buildingBlue: <BuildingBlue />,
    buildingGreen: <BuildingGreen />,
    cartBlue: <CartBlue />,
    caseBlue: <CaseBlue />,
    flagBlue: <FlagBlue />,
    interRao: <InterRao />,

    //section logos documents
    generalDocs: <GeneralDocs />,
    fz43: <Fz43 />,
    saleColorful: <SaleColorful />,
    rubIcon: <RubIcon />,
    basket: <Basket />,
    files2: <Files2 />,

    //services
    credit: <Credit />,
    creditOnDogovor: <CreditOnDogovor />,
    specAcc: <SpecAcc />,
    CreditOnProcurement: <CreditOnProcurement />,
    factoring: <Factoring />,
    AcompanyTenders: <AcompanyTenders />,
    icwe: <Icwe />,
    EdinoeOkno: <EdinoeOkno />,
    Tkp: <Tkp />,
    Inovation: <Inovation />,
    Stonks: <Stonks />,
    Importozamewenie: <Importozamewenie />,
    Msp: <Msp />,
    Education: <Education />,
    PreTZ: <PreTZ />,
    PreFindProcurement: <PreFindProcurement />,

    circleCheck: <CircleCheck />,
    circleWarning: <CircleWarning />,

    support: <Support />,
    client: <Client />,

    loupePlus: <LoupePlus />,

    // browsers
    chrome: <Chrome />,
    edge: <Edge />,
    firefox: <Firefox />,
    opera: <Opera />,
    safari: <Safari />,

    people: <People />,
    loupe: <Loupe />,

    arrowUp: <ArrowUp />,
    arrowRight: <ArrowRight />,
    tariffGift: <TariffGift />
};

// {SvgElements[name]}
