import styled, { css } from 'styled-components';
import { adaptiveHardValues, color, font, hover, mediaBreakpointUp, scrollbar } from '@/style/mixins';
import Select from 'react-select';
import variables from '@/style/variables';

const INPUT_PADDINGS = css<{ withValue?: boolean }>`
    padding: ${({ withValue }) => (withValue ? '24px 30px 7px 16px' : '15px 30px 15px 16px')};

    ${mediaBreakpointUp('md')} {
        padding: ${({ withValue }) => (withValue ? '24px 40px 7px 20px' : '15px 40px 15px 20px')};
    }
`;

export const Container = styled(Select)`
    position: relative;

    ${hover(`
            .InputSelect__control {
                background-color: ${color('ui-bg-hover')};
            }
    `)};

    .InputSelect__control {
        width: 100%;
        min-height: 48px;
        border-radius: ${variables.corners.smallUi};
        border: none !important;
        line-height: 1.1;
        box-shadow: none;
        background-color: ${color('ui-bg')};
        ${font('text2-sb')};

        transition: color 0.3s ease-in-out, border-radius 0.3s, background-color 0.3s ease-in-out;

        ${mediaBreakpointUp('md')} {
            min-height: 52px;
        }

        &.InputSelect__control--menu-is-open {
            .InputSelect__indicator {
                transform: scaleY(-1);
            }
        }
    }

    .InputSelect__value-container {
        line-height: 1.1;

        & > :last-child {
            height: 1.05em;
            margin: 0;
        }
    }

    .InputSelect__placeholder {
        color: ${color('ui-label-default')};
    }

    .InputSelect__indicator {
        padding-top: 5px;
        padding-bottom: 5px;
        transition: transform 0.3s;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .InputSelect__single-value {
        color: inherit;
    }

    .InputSelect__indicator-separator {
        display: none;
    }

    .InputSelect__menu {
        border-radius: ${variables.corners.smallUi};
        box-shadow: 0px 4px 12px 0px rgba(47, 71, 93, 0.2);
        margin-top: 4px;
        ${font('text2-sb')};
        ${adaptiveHardValues('max-height', undefined, 208, 288)};
        background-color: ${color('white')};
    }

    .InputSelect__group-label {
        ${font('text1-b')};
        text-transform: none;
    }

    .InputSelect__menu-list {
        ${scrollbar};
        scrollbar-color: ${color('DeepBlue/300')} ${color('DeepBlue/100')};
        border-radius: ${variables.corners.smallUi};
        padding: 0;
        ${adaptiveHardValues('max-height', undefined, 208, 288)};

        &::-webkit-scrollbar {
            width: 2px;
        }
    }

    .InputSelect__option {
        cursor: pointer;
        background-color: initial;
        color: ${color('text-secondary')};
        ${INPUT_PADDINGS}

        ${mediaBreakpointUp('xl')} {
            ${hover(`background-color: ${color('ui-bg')};`)};
        }

        &:active {
            background-color: ${color('ui-bg')};
        }

        &--is-selected {
            pointer-events: none;
            cursor: initial;
            background-color: ${color('ui-bg-hover')};
        }
    }

    .InputSelect__value-container--is-multi {
        gap: 4px;
    }

    .InputSelect__multi-value {
        cursor: pointer;
        position: relative;
        border-radius: ${variables.corners.smallUi};
        background-color: ${color('BlueGray/200')};
        transition: background-color 0.3s ease-in-out;
        margin: 0;

        &:hover {
            background-color: ${color('BlueGray/400')};
        }

        &__label {
            padding: 4px 25px 4px 12px;
            color: ${color('ui-label-default')};
            ${font('text2-sb')}
        }

        &__remove {
            position: absolute;
            width: 100%;
            height: 100%;
            justify-content: flex-end;
            padding-right: 8px;
            color: ${color('ui-icon-default')};

            &:hover {
                color: ${color('ui-icon-default')};
                background-color: initial;
            }

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
`;

export default Container;
