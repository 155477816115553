import React from 'react';
import { Container } from './ModalBtn.styled';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { setModal } from '@/redux/modal/modalSlice';
import { ButtonStyleParameters } from '@/style/mixins';
import { sendMetrik } from '@/helpers';
import { ModalBtnI } from '@/interfaces';

const mapStateToProps = ({ modal }: RootState) => ({
    modal
});
const mapDispatchToProps = { setModal };
const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export type ModalBtnView = 'simple' | 'rectangle';

export interface ModalBtnProps {
    className?: string;
    id: string;
    dataPosition?: string;
    dataAddress?: string;
    modalTitle?: string;
    sub_title?: string;
    form_code?: string;
    is_new_form?: string;
    view?: {
        viewType: ModalBtnView;
        buttonParams: ButtonStyleParameters;
    };
    haveBg?: boolean;
    deepBlue?: boolean;
    as?: React.ElementType;
    clickOnMapButton?: {
        event: string;
        category: string;
        action: string;
        onlyYm?: boolean;
    };
    onOpenModalFormMetrik?: {
        event: string;
        category: string;
        actions: [
            {
                key: string;
                value?: string;
            },
            {
                key: string;
            }
        ];
    };
    actionValue?: string;
    fromFooter?: boolean;
    altDescription?: ModalBtnI['altDescription'];
    optionalId?: ModalBtnI['optionalId'];
    offAlighSelf?: boolean;
}

const ModalBtn: React.FC<ModalBtnProps & PropsFromRedux> = ({
    children,
    className,
    id,
    view = { viewType: 'simple', buttonParams: [true, false] },
    setModal,
    haveBg,
    as,
    clickOnMapButton,
    onOpenModalFormMetrik,
    actionValue,
    fromFooter,
    altDescription,
    optionalId,
    deepBlue,
    offAlighSelf,
    dataPosition,
    dataAddress,
    modalTitle,
    form_code
}) => {
    return (
        <Container
            className={className}
            view={view}
            haveBg={haveBg}
            deepBlue={deepBlue}
            offAlighSelf={offAlighSelf}
            id={id}
            form_code={form_code}
            data-position={dataPosition}
            data-address={dataAddress}
            onClick={() => {
                setModal({
                    id,
                    open: true,
                    fromFooter: fromFooter && true,
                    altDescription,
                    optionalId: optionalId,
                    form_code: form_code,
                    mapInfo: {
                        address: dataAddress || '',
                        position: dataPosition ? JSON.parse(dataPosition) : null,
                        title: modalTitle
                    }
                });
                clickOnMapButton &&
                    sendMetrik(
                        clickOnMapButton.event,
                        clickOnMapButton.category,
                        { [clickOnMapButton.action]: children },
                        '',
                        clickOnMapButton.onlyYm
                    );
                onOpenModalFormMetrik &&
                    sendMetrik(onOpenModalFormMetrik.event, onOpenModalFormMetrik.category, {
                        [onOpenModalFormMetrik.actions[0].key]: onOpenModalFormMetrik.actions[0].value || actionValue,
                        [onOpenModalFormMetrik.actions[1].key]: id
                    });
            }}
            as={as}
        >
            {children}
        </Container>
    );
};

export default connector(ModalBtn);
