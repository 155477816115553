import styled from 'styled-components';
import Checkbox from '@/components/ui/Checkbox/Checkbox';
import { color, font, mediaBreakpointDown, offset } from '@/style/mixins';
import { BaseOutsideOffsetsUI } from '@/interfaces';

export const Container = styled(Checkbox)<BaseOutsideOffsetsUI>`
    align-items: center;
    position: relative;
    ${font('text3-sb')};
    color: ${color('text-tertiary')};

    ${({ outsideTOffset }) => outsideTOffset && offset(outsideTOffset, 'margin-top')};
    ${({ outsideBOffset }) => outsideBOffset && offset(outsideBOffset, 'margin-bottom')};

    ${mediaBreakpointDown('sm')} {
        align-items: flex-start;
    }

    &.checkboxConfirm p {
        margin-top: 0;
        font: inherit;
    }

    span {
        flex-shrink: 0;
    }

    a {
        display: inline;
        margin-left: 0.25em;
        text-decoration: underline;
    }

    &._error {
        span {
            border-color: ${color('Error')};
        }
    }
`;
