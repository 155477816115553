import { useEffect, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import useIsMatches from './useIsMatches';
import Cookies from 'js-cookie';
import debounce from 'lodash.debounce';
import ResizeObserver from 'resize-observer-polyfill';
import { COOKIE_MESSAGE_KEY, PROMOTIONAL_POPUP_CLOSED_KEY } from '@/lib/variables';

const MOBILE_BOTTOM = '20px';
const TABLET_BOTTOM = '32px';
const DESKTOP_BOTTOM = '32px';

const MOBILE_RIGHT = '20px';
const DESKTOP_RIGHT = '32px';

const MOBILE_POPUP_BOTTOM = '16px';
const DESKTOP_POPUP_BOTTOM = '12px';

interface ElementStyles {
    bottom?: string;
    right?: string;
}

export const useWebimButtonOffset = (isFirstCall = false) => {
    const { locale } = useRouter();
    const isMobile = useIsMatches('md');
    const isTablet = useIsMatches('xl');

    const setStyles = useCallback((element: HTMLElement | null, styles: ElementStyles) => {
        if (element) {
            Object.assign(element.style, styles);
        }
    }, []);

    const calculateWebimBottom = useCallback(
        (hasCookies: boolean, isPopupClosed: boolean, cookieHeight: number, popupHeight: number): string => {
            if (isMobile) {
                if (hasCookies && !isPopupClosed) {
                    return `${cookieHeight + popupHeight + 56}px`;
                } else if (hasCookies && isPopupClosed) {
                    return `${cookieHeight + 36}px`;
                } else if (!hasCookies && !isPopupClosed) {
                    return `${popupHeight + 40}px`;
                }
                return MOBILE_BOTTOM;
            } else if (isTablet) {
                return !hasCookies && !isPopupClosed ? `${popupHeight + 40}px` : TABLET_BOTTOM;
            }
            return DESKTOP_BOTTOM;
        },
        [isMobile, isTablet]
    );

    const calculatePopupBottom = useCallback(
        (hasCookies: boolean, cookieHeight: number): string => {
            if (hasCookies) {
                return isMobile || isTablet ? `${cookieHeight + 32}px` : DESKTOP_POPUP_BOTTOM;
            }
            return isMobile ? MOBILE_POPUP_BOTTOM : DESKTOP_POPUP_BOTTOM;
        },
        [isMobile, isTablet]
    );

    const setWebimButtonsOffsets = useMemo(
        () =>
            debounce(() => {
                const webimButtonMain = document.querySelector('a.webim_button.webim-button-slider') as HTMLElement;
                const webimButtonLoading = document.querySelector('.webim-button-loading') as HTMLElement;
                const cookieMessage = document.querySelector('.cookie-message') as HTMLElement;
                const popup = document.querySelector('.popup') as HTMLElement;

                const hasCookies = !Cookies.get(`${COOKIE_MESSAGE_KEY}_${locale}`);
                const isPopupClosed = !!sessionStorage.getItem(PROMOTIONAL_POPUP_CLOSED_KEY);
                const cookieMessageHeight = cookieMessage?.offsetHeight ?? 0;
                const popupHeight = popup?.offsetHeight ?? 0;

                const commonWebimRight = isTablet ? MOBILE_RIGHT : DESKTOP_RIGHT;
                const commonWebimBottom = calculateWebimBottom(hasCookies, isPopupClosed, cookieMessageHeight, popupHeight);
                const commonPopupBottom = calculatePopupBottom(hasCookies, cookieMessageHeight);

                setStyles(webimButtonMain, { bottom: commonWebimBottom, right: commonWebimRight });
                setStyles(webimButtonLoading, { bottom: commonWebimBottom, right: commonWebimRight });
                setStyles(popup, { bottom: commonPopupBottom });
            }, 200),
        [locale, isTablet, calculateWebimBottom, calculatePopupBottom, setStyles]
    );

    useEffect(() => {
        if (!isFirstCall) return;

        const resizeObserver = new ResizeObserver(setWebimButtonsOffsets);
        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };
    }, [setWebimButtonsOffsets]);

    return { setWebimButtonsOffsets };
};
