import breakpoints, { Breakpoints } from './breakpoints';
import variables, { FontFamily, Offsets } from './variables';
import colors, { Colors } from './colors';
import typography, { Typography } from './typography';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { rgba } from 'polished';

/**
 * @param breakpoint - целевой Breakpoint
 * @param value - Значение на целевом экране
 * @return Медиа запрос активный для заданного Breakpoint и выше
 * @example
 * ${mediaBreakpointUp('xl')} {
 *     // styles
 * }
 */
export const mediaBreakpointUp: (breakpoint: Breakpoints, value?: number) => string = (breakpoint, value = 0) => {
    return `@media(min-width: ${breakpoints[breakpoint] + value}px)`;
};

/**
 * @param breakpoint - целевой Breakpoint
 * @return Медиа запрос активный для заданного Breakpoint и ниже
 * @example
 * ${mediaBreakpointDown('xl')} {
 *     // styles
 * }
 */
export const mediaBreakpointDown: (breakpoint: Breakpoints) => string = (breakpoint) => {
    return `@media(max-width: ${breakpoints[breakpoint] - 1}px)`;
};

/**
 * @param value - Значение на целевом экране
 * @param screenWidth - Ширина целевого экрана (число или Breakpoint)
 * @param shouldRound - Округлять ли результат до 2х чисел после запятой (может понадобиться в IE 11)
 * @returns Относительное значение в формате vw. Результат изменяется вместе с шириной экрана
 * @example
 * width: ${vw(480)};
 *
 * ${mediaBreakpointDown('md')} {
 *     width: ${vw(240, 'md')};
 * }
 */
export const vw: (value: number, screenWidth?: Breakpoints | number, shouldRound?: boolean) => string = (
    value,
    screenWidth: Breakpoints | number = 1920,
    shouldRound = false
) => {
    const widthInPx = typeof screenWidth === 'number' ? screenWidth : screenWidth === 'xl' ? 1280 : breakpoints[screenWidth];
    // В IE 11 в некоторых свойствах (например, margin) число округляется до 2х знаков после запятой
    const ratio = shouldRound ? Math.round((value / widthInPx) * 100) / 100 : value / widthInPx;
    return `${ratio * 100}vw`;
};

/**
 * @param value - Значение на целевом экране
 * @param screenHeight - Высота целевого экрана
 * @returns Относительное значение в формате vh. Результат изменяется вместе с высотой экрана
 * @example
 * height: ${vh(480)};
 *
 * ${mediaBreakpointDown('md')} {
 *     height: ${vw(240, 'md')};
 * }
 */
export const vh: (value: number, screenHeight?: number) => string = (value, screenHeight = 1080) => {
    return `${(value / screenHeight) * 100}vh`;
};

/**
 * @param value - Цвет (название или номер);
 * @param opacity - Прозрачность;
 * @param gradient - Градиент;
 * @example
 * color: ${color('white')};
 * color: ${color('black', 0.5)};
 */
export const color: (value: Colors, opacity?: number, gradient?: boolean) => string = (value, opacity = 1, gradient = false) => {
    return gradient ? colors[value] : rgba(colors[value], opacity);
};

/**
 * @param value - Количество колонок
 * @returns Относительное значение в формате vw. Результат изменяется вместе с шириной экрана
 * @example
 * width: ${cols(4)};
 */
export const cols: (value: number) => string = (value) => {
    return `${variables.col * value}vw`;
};

/**
 * @param name - Название типа текста
 * @returns Стандартные стили для указанного типа текста
 * @example
 * ${font('h1')};
 */
export const font: (name: Typography) => FlattenSimpleInterpolation = (name) => {
    return typography[name];
};

/**
 * @param name - Название семейства шрифтов
 * @returns Стандартные стили для указанного семейства шрифтов
 */
export const fontFamily: (name: FontFamily) => FlattenSimpleInterpolation = (name) => {
    return css`
        font-family: ${variables.fonts[name]};
    `;
};

/**
 * @description Применение стилей для эффектов ховера с помощью медиа-запроса.
 * @description Медиа-запрос определяет может ли устройство использовать ховеры
 * @param styles - Стили, применяемые для ховера
 * @example
 * ${hover(css`
 *     opacity: 0.5;
 * `)}
 * фикс для IE11 тк. в нем не работает медиазапрос @media (hover: hover)
 * ._is-ie & {
 *    &:hover {
 *     ${styles}
 *    }
 *  }
 */
export const hover = (styles: string): FlattenSimpleInterpolation => {
    return css`
        @media (hover: hover) {
            &:hover {
                ${styles}
            }
        }
        ._is-ie & {
            &:hover {
                ${styles}
            }
        }
    `;
};
/**
 * Плавное изменение значения от максимального (maxValue) к минимальному (minValue)
 * в рамках максимального (maxPoint) и минимального (minPoint) занчения ширины экрана
 * пример: margin-right: ${smoothValue(180, 200, 1280, 1440, 'w')};
 */
export const smoothValue = (minValue: number, maxValue: number, minPoint: number, maxPoint: number, dimension: string) => {
    return `calc(
        ${minValue}px
        + ${maxValue - minValue}
        * (100v${dimension} - ${minPoint}px)
        / (${maxPoint - minPoint})
    )`;
};

/**
 * @returns возвращает property для всех Breakpoints
 */
export const allBreakpointValue = (
    property: string,
    desktop1920: number,
    desktop1440: number,
    desktop1280?: number,
    tablet768?: number,
    mobile375?: number
) => {
    let result = '';
    if (mobile375) {
        result += ` ${property}: ${vw(mobile375, 375)};`;
    }
    if (tablet768) {
        result += ` ${mediaBreakpointUp('md')} {
            ${property}: ${vw(tablet768, 768)};
        }; `;
    }

    if (desktop1280) {
        result += ` ${mediaBreakpointUp('xl')} {
            ${property}: ${vw(desktop1280, 1280)};
        }; `;
    }

    result += `
        ${mediaBreakpointUp('xxl')} {
            ${property}: ${vw(desktop1440, 1440)};
        }

        ${mediaBreakpointUp('fhd')} {
            ${property}: ${vw(desktop1920, 1920)};
        }
    `;
    return result;
};

export const adaptiveHardValues = (property: string, desktop1280?: number | null, tablet768?: number | null, mobile375?: number | null) => {
    let result = '';

    if (mobile375) {
        result += ` ${property}: ${mobile375}px;`;
    }

    if (tablet768) {
        result += ` ${mediaBreakpointUp('md')} {
            ${property}: ${tablet768}px;
        }; `;
    }

    if (desktop1280) {
        result += ` ${mediaBreakpointUp('xl')} {
            ${property}: ${desktop1280}px;
        }; `;
    }

    return result;
};

/**
 * @param name - Название отступа из offsets
 * @param property - свойство
 * @param isNegative - определяет, должны ли значения быть отрицательными
 * @returns возвращает отступ для всех Breakpoints
 */

export const offset = (name: Offsets, property: string = 'margin-top', isNegative?: boolean): FlattenSimpleInterpolation => {
    if (name === 'none') {
        return css`
            ${property}: 0;
        `;
    }

    return css`
        ${adaptiveHardValues(
            property,
            variables.offsets[name].desktop1280 * (isNegative ? -1 : 1),
            variables.offsets[name].tablet768 * (isNegative ? -1 : 1),
            variables.offsets[name].mobile375 * (isNegative ? -1 : 1)
        )}
    `;
};

/***
 * @param property - необходимое сво-во
 * @returns стандартные боковые отступы
 */
export const sideOffsets = (property: string = 'padding', isNegative?: boolean, customOffset?: (typeof variables)['offset']) => {
    const offset = customOffset || variables.offset;

    return css`
        ${property}-left: ${vw(offset.mobile * (isNegative ? -1 : 1), 375)};
        ${property}-right: ${vw(offset.mobile * (isNegative ? -1 : 1), 375)};

        ${mediaBreakpointUp('md')} {
            ${property}-left: ${vw(offset.tablet * (isNegative ? -1 : 1), 768)};
            ${property}-right: ${vw(offset.tablet * (isNegative ? -1 : 1), 768)};
        }

        ${mediaBreakpointUp('xl')} {
            ${property}-left: ${vw(offset.desktop1280 * (isNegative ? -1 : 1), 1280)};
            ${property}-right: ${vw(offset.desktop1280 * (isNegative ? -1 : 1), 1280)};
        }

        ${mediaBreakpointUp('xxl')} {
            ${property}-left: ${vw(offset.desktop1440 * (isNegative ? -1 : 1), 1440)};
            ${property}-right: ${vw(offset.desktop1440 * (isNegative ? -1 : 1), 1440)};
        }

        ${mediaBreakpointUp('fhd')} {
            ${property}-left: ${vw(offset.desktop1920 * (isNegative ? -1 : 1), 1920)};
            ${property}-right: ${vw(offset.desktop1920 * (isNegative ? -1 : 1), 1920)};
        }
    `;
};

export const offsetMain = (bottom = true) => {
    return css`
        ${offset('xxxl', 'padding-top')};
        ${bottom ? offset('xxxl', 'padding-bottom') : null}
        ${sideOffsets()};
    `;
};

/**
 * Общие стили для карточек с иконками внизу и стерлками в названии
 * */
export const commonCardsStyles = css`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;

    ${allBreakpointValue('width', 434, 326, 289, 340, 335)};

    ${mediaBreakpointDown('md')} {
        padding: ${vw(16, 375)} ${vw(20, 375)};
    }
    ${offset('m', 'padding')};

    ${mediaBreakpointUp('md')} {
        flex-direction: column;
    }

    p {
        ${font('h5-b')};
        position: relative;
        padding-right: 15px;

        ${mediaBreakpointDown('md')} {
            max-width: 236px;
        }

        ${mediaBreakpointDown('xl')} {
            max-width: 260px;
        }

        ${mediaBreakpointUp('md')} {
            padding-right: 12px;
        }

        ${mediaBreakpointUp('xxl')} {
            padding-right: 24px;
        }

        svg {
            stroke: currentColor;
            display: inline-block;
            position: absolute;
            transform: translateX(10px);
            width: 6px;
            height: 13px;
            margin-top: 3px;

            ${mediaBreakpointUp('xxl')} {
                transform: translateX(20px);
                transition: transform 0.3s ease-in-out;
                width: 8px;
                height: 16px;
                margin-top: ${smoothValue(3, 7, 1440, 1920, 'w')};
            }

            ${mediaBreakpointUp('fhd')} {
                width: 0.28em;
                height: 0.57em;
                margin-top: 0.25em;
            }
        }
    }

    p:nth-of-type(2) {
        ${font('text1-l')};
        margin-top: 10px;
        ${mediaBreakpointUp('md')} {
            margin-top: 12px;
        }
        ${mediaBreakpointUp('xxl')} {
            margin-top: 16px;
        }
    }

    & > svg {
        flex-shrink: 0;
        position: relative;
        fill: currentColor;
        margin-left: auto;
        margin-top: auto;
        ${allBreakpointValue('width', 40, 30, 27, 20, 20)};
        ${allBreakpointValue('height', 40, 30, 27, 20, 20)};
    }

    && svg.transparentable {
        fill: transparent;
        stroke: currentColor;
    }
`;

export const svgMaskStyle = css`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: none;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    ${mediaBreakpointUp('xl')} {
        display: block;
    }

    svg {
        width: 100%;
        height: 100%;
        display: block;
        transition: fill 0.3s ease-in-out;
    }
`;

export const buttonStyle = (
    transparent: boolean = true,
    small: boolean = false,
    colorBg: Colors = 'white',
    colorText: Colors = 'text-primary',
    colorTextHover: Colors = 'text-primary',
    colorBgHover: Colors = 'BlueGray/200'
) => {
    return css`
        position: relative;
        ${font('ui')};
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        padding: ${small ? '0 20px' : '0 20px'};
        border: 1px solid ${transparent ? `${color('Content-02')}` : `${color(colorBg)}`};
        border-radius: 10px;
        cursor: pointer;
        color: ${color(colorText)};
        background-color: ${transparent ? 'transparent' : `${color(colorBg)}`};
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

        ${mediaBreakpointUp('md')} {
            padding: ${small ? '0 24px' : '0 24px'};
            height: 52px;
        }

        ${hover(`
            color: ${color(colorTextHover)};
            background-color: ${color(colorBgHover)};
            border-color: ${transparent ? `${color('Content-01', 0.5)}` : `${color(colorBgHover)}`};
        `)};

        &:disabled {
            cursor: default;
            background-color: ${transparent ? 'transparent' : `${color(colorBg, 0.2)}`};
            border-color: ${transparent ? `${color('black', 0.2)}` : `${color(colorBg, 0.2)}`};
            background-clip: padding-box;

            span {
                opacity: ${colorBg === 'white' ? 0.2 : 0.8};
            }

            ${hover(`
                border-color: ${transparent ? `${color('black', 0.2)}` : `${color(colorBg, 0.2)}`};

                span {
                    opacity: ${colorBg === 'white' ? 0.2 : 0.8};
                }
            `)};
        }

        span {
            transition: opacity 0.3s ease-in-out;
        }
    `;
};
export type ButtonStyleParameters = Parameters<typeof buttonStyle>;

export const scrollbar = css`
    scrollbar-width: thin;
    scrollbar-color: ${color('link-default')} transparent;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${color('Bg-Light')};
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: ${color('link-default')};
    }
`;

export const scrollbarImg = css`
    scrollbar-color: transparent;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        width: none;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: transparent;
    }
`;

export const squareNumber = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${allBreakpointValue('padding', 6, 5, 4, 5, 5)}
    min-width: 48px;
    height: 48px;
    ${font('text2-sb')};
    background-color: ${color('Content-03')};
    color: ${color('white')};
    border-radius: ${variables.corners.smallUi};

    ${mediaBreakpointUp('md')} {
        min-width: 60px;
        height: 60px;
    }
`;
/**
 * Общие стили для срезов углов карточек для карточек такого типа.
 * */
export const anglePositionStyles = (position: string) => {
    switch (position) {
        case 'bottom-right':
            return css`
                bottom: -1px;
                right: -1px;
                transform: rotate(-90deg);
            `;
        case 'top-left':
            return css`
                top: -1px;
                left: -1px;
                transform: rotate(90deg);
            `;
        case 'top-right':
            return css`
                top: -1px;
                right: -1px;
                transform: rotate(180deg);
            `;
        case 'bottom-left':
            return css`
                bottom: -1px;
                left: -1px;
            `;
        default:
            return '';
    }
};
