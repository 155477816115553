import styled, { css } from 'styled-components';
import { font, hover, color, mediaBreakpointUp } from '@/style/mixins';
import variables from '@/style/variables';
import { Colors } from '@/style/colors';

export const Container = styled.a<{ bg?: Colors; withMaxWidth?: boolean }>`
    cursor: pointer;
    position: relative;
    display: block;
    border-radius: ${variables.corners.smallCard};
    transition: background-color 0.3s ease-in-out;

    ${({ bg }) =>
        bg
            ? css`
                  background-color: ${color('BlueGray/100')};

                  ${DocumentType} {
                      background-color: ${color('BlueGray/200')};
                  }

                  ${hover(`
                    background-color: ${color('BlueGray/200')};

                ${DocumentType} {
                    background-color: ${color('BlueGray/400')};
                }
            `)}
              `
            : css`
                  background-color: ${color('white')};

                  ${DocumentType} {
                      background-color: ${color('BlueGray/100')};
                  }

                  ${hover(`
                    background-color: ${color('BlueGray/200')};

                ${DocumentType} {
                    background-color: ${color('BlueGray/400')};
                }
             `)}
              `};

    ${({ withMaxWidth }) =>
        withMaxWidth &&
        css`
            ${mediaBreakpointUp('xl')} {
                max-width: 50%;
            }
        `}
`;

export const DocumentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 10.5px 8px 10.5px 12px;
`;

export const DocumentType = styled.div`
    ${font('text3-sb')};
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    text-transform: uppercase;
    white-space: nowrap !important;
    padding: 8px 12px;
    min-width: 52px;
    max-width: 70px;
    height: 30px;
    border-radius: ${variables.corners.smallUi};
    transition: background-color 0.3s ease-in-out;
`;

export const DocumentTitleArchive = styled.div`
    ${font('t1-body-sb')};

    ${mediaBreakpointUp('lg')} {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const DocumentTitle = styled.div`
    ${font('t1-body-sb')};

    ${mediaBreakpointUp('lg')} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`;
