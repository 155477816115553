import styled from 'styled-components';
import { hover, mediaBreakpointUp } from '@/style/mixins';
import Image from '../Image/Image';
import variables from '@/style/variables';

export const Container = styled.div`
    position: fixed;
    z-index: 899;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: opacity 0.7s ease-in-out, visibility 0.7s ease-in-out;
    right: 20px;
    bottom: 20px;

    ${mediaBreakpointUp('xl')} {
        right: 32px;
        bottom: 32px;
    }

    &.hidesBtn {
        opacity: 0;
        visibility: hidden;
    }
`;

export const LoadingBtn = styled.button`
    cursor: pointer;
    padding: 0;
    margin: 0;
    border-radius: ${variables.corners.smallCard} ${variables.corners.smallCard} 2px ${variables.corners.smallCard};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    background: none;

    ${hover(`
        transform: scale(1.03);  
    `)}
`;

export const LoadingBtnImage = styled(Image)`
    width: 48px;
    height: 48px;

    ${mediaBreakpointUp('md')} {
        width: 64px;
        height: 64px;
    }
`;
