import { mediaBreakpointUp, color, font } from '@/style/mixins';
import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    position: relative;
    text-transform: uppercase;
    display: flex;
    z-index: 1;
    ${font('text3-sb')}
    transition: color 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        margin-left: auto;
    }

    &:hover {
        color: ${color('text-primary', 0.6)};

        ._dark &,
        ._openSandwich & {
            color: ${color('text-oncolor-primary', 0.6)};
        }

        & > div {
            opacity: 1;
            visibility: visible;
        }

        svg {
            transform: scaleY(-1);
        }
    }

    svg {
        width: 10px;
        height: 10px;
        stroke: currentColor;
        transition: stroke 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    span {
        width: max-content;
        display: flex;
        gap: 3px;
        justify-content: center;
        align-items: center;
    }

    & > span {
        cursor: pointer;
    }
`;

export const List = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    width: max-content;
    background-color: ${color('Content-01')};
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 ${color('black', 0.1)};
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

export const ListItem = styled.span`
    a,
    span {
        color: ${color('text-primary')};
        padding: 8px 8px 12px;

        &:last-child {
            padding-bottom: 8px;
        }
    }

    span {
        cursor: default;
    }

    a {
        transition: color 0.3s ease-in-out;

        ${mediaBreakpointUp('xl')} {
            &:hover {
                color: ${color('text-primary', 0.6)};
            }
        }
    }
`;
