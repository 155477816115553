import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import debounce from 'lodash.debounce';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * @description Определение высоты плашки важного уведомления в шапке сайта для последующей установки отступа основного контента.
 */

const useImportantMessageHeight = () => {
    const [layOutOffset, setLayOutOffset] = useState<number>(0);
    const { hide } = useSelector(({ importantMessage }: RootState) => importantMessage);

    const getImportantMessageHeight = () => {
        if (typeof window !== 'undefined') {
            const importantMessageElement = document.querySelector('._important') as HTMLElement;
            return importantMessageElement ? importantMessageElement.offsetHeight : 0;
        }
    };

    const resizeHandler = debounce(() => {
        const importantMessageHeight = getImportantMessageHeight();
        if (importantMessageHeight) {
            setLayOutOffset(importantMessageHeight - 1);
        }
    }, 100);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        // Если сообщение скрыто, обнуляем отступ
        if (hide) {
            setLayOutOffset(0);
        } else {
            const importantMessageElement = document.querySelector('._important') as HTMLElement;
            if (importantMessageElement) {
                resizeHandler();
            }
        }
    }, [hide, resizeHandler]);

    return layOutOffset;
};

export default useImportantMessageHeight;
