import { color, buttonStyle } from '@/style/mixins';
import styled, { css } from 'styled-components';
import { ButtonProps } from './Button';
import CircularAnimation from '../CircularAnimation/CircularAnimation';

export const Container = styled.button<Required<Pick<ButtonProps, 'haveBg' | 'small' | 'bgColor' | 'textColor'>>>`
    ${({ bgColor, textColor }) => buttonStyle(false, false, bgColor, textColor)}

    &:disabled {
        border-color: ${color('DeepBlue/200')};
        background-color: ${color('DeepBlue/200')};

        span {
            opacity: 0.8;
        }
    }
`;

export const Text = styled.span<Pick<ButtonProps, 'isLoading'>>`
    ${({ isLoading }) =>
        isLoading &&
        css`
            opacity: 0;
        `}
`;

export const Spinner = styled(CircularAnimation)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .spinner .path {
        stroke: currentColor;
    }
`;
