import React from 'react';
import useTranslation from '@/hooks/useTranslation';

import { Container, ErrorPageText, ErrorPageTitle, ErrorPageButton, ButtonError } from './ErrorPageBlock.styled';
import Opera from '@/icons/browsers/opera.svg';
import Edge from '@/icons/browsers/edge.svg';
import Firefox from '@/icons/browsers/firefox.svg';
import Chrome from '@/icons/browsers/chrome.svg';
import Safari from '@/icons/browsers/safari.svg';
import { useRouter } from 'next/router';
import { isDetailedProcedurePage } from '@/helpers/isDetailedProcedurePage';
import { getProcedureSectionPath } from '@/helpers/getProcedureSectionPath';

interface ErrorPageBlockProps {
    error: ErrorsPageTypes;
    onClick?: (e: React.SyntheticEvent) => void;
}

interface IErrorPage {
    title?: string;
    text?: string;
    btnText?: string;
}

const BROWSERS = [
    { href: 'https://www.opera.com/', icon: <Opera /> },
    { href: 'https://www.microsoft.com/edge', icon: <Edge /> },
    { href: 'https://www.mozilla.org/firefox/', icon: <Firefox /> },
    { href: 'https://www.google.com/chrome/', icon: <Chrome /> },
    { href: 'https://www.apple.com/ru/safari/', icon: <Safari /> }
];

type ErrorsPageTypes =
    | 'techWorks'
    | 'oldBrowser'
    | '404'
    | '403'
    | '500'
    | 'procedureNotAuthorized'
    | 'procedureNotFound'
    | 'applicationError';

const ErrorPageBlock: React.FC<ErrorPageBlockProps> = ({ error, onClick }) => {
    const { t } = useTranslation('common');
    const path = useRouter()?.asPath.split('?')[0];
    const thisIsDetailedProcedurePage = isDetailedProcedurePage(path);

    const page: IErrorPage = t(`errorPages.${thisIsDetailedProcedurePage ? 'procedureNotFound' : error}`, { returnObjects: true });

    return (
        <Container>
            <ErrorPageTitle // @ts-ignore
                dangerouslySetInnerHTML={{ __html: page?.title }}
            />

            <ErrorPageText // @ts-ignore
                dangerouslySetInnerHTML={{ __html: page?.text ?? t('errorPages.defaultText') }}
            />

            {onClick ? (
                <ButtonError onClick={onClick}>{page?.btnText}</ButtonError>
            ) : (
                <>
                    {error === 'oldBrowser' ? (
                        <div>
                            {BROWSERS.map((browser, i) => (
                                <a href={browser.href} key={i} target="_blank" rel="noreferrer">
                                    {browser.icon}
                                </a>
                            ))}
                        </div>
                    ) : (
                        <ErrorPageButton
                            text={thisIsDetailedProcedurePage ? page.btnText : t('errorPages.onMainPage')}
                            href={thisIsDetailedProcedurePage ? getProcedureSectionPath(path) : '/'}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

export default ErrorPageBlock;
