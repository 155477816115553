import React from 'react';
import dynamic from 'next/dynamic';
import { Container } from './MenuList.styled';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';
import { LinkI } from '@/interfaces';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';
import LinkDown from '@/components/ui/LinkDown/LinkDown';
const Spoiler = dynamic(() => import('@/components/blocks/Spoiler/Spoiler'));

interface MenuListProps {
    id?: string;
    download?: boolean;
    text: string;
    href?: string;
    subLinks?: LinkI[];
}

const MenuList: React.FC<MenuListProps[]> = (item) => {
    return (
        <Container>
            {item.map((item, index) => (
                <li key={index}>
                    {item.subLinks ? (
                        <Spoiler key={index} header={<span>{item.text}</span>}>
                            <ul>
                                {item.subLinks.map((item, i) => (
                                    <li key={i}>{PotentiallyLink(item)}</li>
                                ))}
                            </ul>
                        </Spoiler>
                    ) : item.id ? (
                        <ModalBtn id={item.id} fromFooter={true}>
                            {item.text}
                        </ModalBtn>
                    ) : item.download ? (
                        <LinkDown {...item} href={item.href || ''} />
                    ) : (
                        PotentiallyLink(item)
                    )}
                </li>
            ))}
        </Container>
    );
};

export default MenuList;
